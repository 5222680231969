import React from 'react';
import type {
  IDialogScreenManagerProviderEnqueueOptionsProps,
  TDialogScreenManagerStackDialogId,
} from './dialog-screen-manager.component';

interface IDialogScreenManagerContextProps {
  stackDialog: (
    content: React.ReactNode,
    options?: IDialogScreenManagerProviderEnqueueOptionsProps
  ) => TDialogScreenManagerStackDialogId;
}

export const DialogScreenManagerContext =
  React.createContext<IDialogScreenManagerContextProps>(
    {} as IDialogScreenManagerContextProps
  );
