import React from 'react';
import { useDialogScreenManager } from '../../navigation/use-dialog-screen-magener.hook';
import { EChargeViewDialogSearchParam } from '../../../views/charges/charge-view-dialog/charge-view-dialog.types';
import { ChargeViewDialogWrapper } from './modal-wrappers/charge-view-dialog-wrapper.component';

export function GlobalModalTrigger() {
  const { stackDialog } = useDialogScreenManager();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const chargeDetailsMatch =
      searchParams.has(EChargeViewDialogSearchParam.KEY) &&
      searchParams.has(EChargeViewDialogSearchParam.ID);

    console.log(
      searchParams.has(EChargeViewDialogSearchParam.KEY),
      searchParams.has(EChargeViewDialogSearchParam.ID)
    );

    if (chargeDetailsMatch) {
      const chargeId = searchParams.get(EChargeViewDialogSearchParam.ID)!;

      stackDialog(<ChargeViewDialogWrapper chargeId={chargeId} />, {
        fullScreen: true,
      });
    }
  }, [stackDialog]);

  return null;
}
