import React from 'react';
import type { ButtonProps } from '@material-ui/core';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { useSnackbar } from '../../feedback/snackbar/use-snackbar.hook';

interface ICopyToClipboardButtonProps extends ButtonProps {
  textToCopy: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    textTransform: 'none',
    letterSpacing: 'normal',
    fontWeight: 400,
    minWidth: 'auto',
  },
  icon: {
    marginTop: theme.spacing(-0.3),
    marginLeft: theme.spacing(1),
  },
}));

export function CopyToClipboardButton(props: ICopyToClipboardButtonProps) {
  const { textToCopy, children, ...rest } = props;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [isCopying, setIsCopying] = React.useState<boolean>(false);

  async function copyToClipboard() {
    if (!textToCopy) {
      return;
    }

    await navigator.clipboard.writeText(textToCopy);
    setIsCopying(true);

    const delay = 1400;

    enqueueSnackbar('Copiado para área de transferência', {
      variant: 'success',
      autoHideDuration: delay,
    });
    setTimeout(() => setIsCopying(false), delay);
  }

  return (
    <Tooltip open={isCopying} title="Copiado">
      <Button
        {...rest}
        className={classes.root}
        disableElevation
        onClick={copyToClipboard}
        variant="text"
      >
        {children}
        <FileCopyOutlined className={classes.icon} fontSize="inherit" />
      </Button>
    </Tooltip>
  );
}
