import React from 'react';
import {
  Button,
  Card,
  CardActions,
  Grid,
  Grow,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CustomerList } from './components/customer-list/customer-list.component';
import type { IHttpBuyer } from '../../types/entities/buyer';
import type { TCreateChargeViewStep } from './create-charge-view.types';
import { ChargeConfig } from './components/charge-config/charge-config.component';
import { ReviseCharge } from './components/revise-charge/revise-charge.component';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSnackbar } from '../../components/feedback/snackbar/use-snackbar.hook';
import Dinero from 'dinero.js';
import moment from 'moment';
import { useAuthContext } from '../../hooks/useAuthContext.hook';
import { api } from '../../services/api';
import { useHistory } from 'react-router-dom';
import type { IHttpCharge } from '../../types/entities/charge-entity';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  cardContent: {
    padding: 0,
  },
  buttonPrevious: {
    [theme.breakpoints.down('sm')]: {
      order: 0,
    },
  },
  buttonNext: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  stepper: {
    flex: 1,
    backgroundColor: theme.palette.background.dark2,
    [theme.breakpoints.down('sm')]: {
      order: 2,
      minWidth: '100%',
      marginTop: theme.spacing(2),
    },
  },
  containerCardActions: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
}));

interface IStepMapperProps {
  number: number;
  label: string;
}

interface ICreateChargeViewConfigProps {
  amount: string;
  description: string;
  installments: string;
  paymentLimitDate: string | null;
  pix: {
    enabled: boolean;
  };
  card: {
    enabled: boolean;
  };
  boleto: {
    enabled: boolean;
  };
  notification: {
    emailEnabled: boolean;
    whatsappEnabled: boolean;
  };
}

const stepNumberMapper = new Map<TCreateChargeViewStep, IStepMapperProps>([
  [
    'select-customers',
    {
      number: 0,
      label: 'Selecionar clientes',
    },
  ],
  [
    'charge-configuration',
    {
      number: 1,
      label: 'Configurar cobrança',
    },
  ],
  [
    'review',
    {
      number: 2,
      label: 'Revisar',
    },
  ],
]);

export function CreateChargeView() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const history = useHistory();

  const { marketplace_id: marketplaceId, id: sellerId } = user;

  const [customers, setCustomers] = React.useState<IHttpBuyer[]>([]);
  const [chargeConfig, setChargeConfig] =
    React.useState<ICreateChargeViewConfigProps>({
      amount: '',
      description: '',
      installments: '',
      paymentLimitDate: null,
      pix: {
        enabled: false,
      },
      card: {
        enabled: false,
      },
      boleto: {
        enabled: false,
      },
      notification: {
        emailEnabled: true,
        whatsappEnabled: false,
      },
    });
  const [step, setStep] =
    React.useState<TCreateChargeViewStep>('select-customers');

  function addCustomer(customer: IHttpBuyer) {
    setCustomers([...customers, customer]);
  }

  function addManyCustomers(customers: IHttpBuyer[]) {
    setCustomers(prevState => [...prevState, ...customers]);
  }

  function removeCustomer(customer: IHttpBuyer) {
    setCustomers(customers.filter(c => c.id !== customer.id));
  }

  function removeManyCustomers(customers: IHttpBuyer[]) {
    setCustomers(prevState =>
      prevState.filter(c => !customers.some(customer => customer.id === c.id))
    );
  }

  function handleAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist();

    setChargeConfig(prevState => ({
      ...prevState,
      amount: event.target.value,
    }));
  }

  function handlePaymentLimitDateChange(momentDate: MaterialUiPickersDate) {
    const date = momentDate?.toISOString() || null;

    setChargeConfig(prevState => ({
      ...prevState,
      paymentLimitDate: date,
    }));
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist();

    setChargeConfig(prevState => ({
      ...prevState,
      description: event.target.value,
    }));
  }

  function handleInstallmentsChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.persist();

    let value = event.target.value.replace(/[^0-9]/g, '');
    const valueAsNumber = Number(value);

    if (valueAsNumber < 1 && value !== '') {
      value = '1';
    } else if (valueAsNumber > 480) {
      value = '480';
    }

    setChargeConfig(prevState => ({
      ...prevState,
      installments: value,
    }));
  }

  function handleEnabledCardToggle() {
    setChargeConfig(prevState => ({
      ...prevState,
      card: {
        enabled: !prevState.card.enabled,
      },
    }));
  }

  function handleEnabledBoletoToggle() {
    setChargeConfig(prevState => ({
      ...prevState,
      boleto: {
        enabled: !prevState.boleto.enabled,
      },
    }));
  }

  function handleEnabledSendEmailToggle() {
    setChargeConfig(prevState => ({
      ...prevState,
      notification: {
        ...prevState.notification,
        emailEnabled: !prevState.notification.emailEnabled,
      },
    }));
  }

  function handleEnabledSendWhatsappToggle() {
    setChargeConfig(prevState => ({
      ...prevState,
      notification: {
        ...prevState.notification,
        whatsappEnabled: !prevState.notification.whatsappEnabled,
      },
    }));
  }

  function handleEnabledPixToggle() {
    setChargeConfig(prevState => ({
      ...prevState,
      pix: {
        enabled: !prevState.pix.enabled,
      },
    }));
  }

  function handlePreviousStep() {
    if (step === 'select-customers') {
      return;
    }

    switch (step) {
      case 'charge-configuration':
        setStep('select-customers');

        if (customers.length === 0) {
        }

        break;
      case 'review':
        setStep('charge-configuration');
        break;
      default:
    }
  }

  async function handleCreateCharge() {
    const installmentsAsNumber = Number(chargeConfig.installments);

    const originalAmount = Dinero({
      amount: Number(chargeConfig.amount),
      currency: 'BRL',
    });
    let amount = Dinero({
      amount: originalAmount.getAmount(),
      currency: originalAmount.getCurrency(),
    });
    let restAmount = Dinero({
      amount: 0,
      currency: originalAmount.getCurrency(),
    });

    if (installmentsAsNumber > 1) {
      amount = amount.divide(installmentsAsNumber, 'DOWN');
      restAmount = originalAmount.subtract(
        amount.multiply(installmentsAsNumber)
      );
    }

    const paymentTypesAccepted: IHttpCharge['paymentTypesAccepted'] = [];

    if (chargeConfig.card.enabled) {
      paymentTypesAccepted.push('credit');
    }

    if (chargeConfig.boleto.enabled) {
      paymentTypesAccepted.push('boleto');
    }

    if (chargeConfig.pix.enabled) {
      paymentTypesAccepted.push('pix');
    }

    const lastAmount =
      restAmount.getAmount() === 0 ? null : amount.add(restAmount).getAmount();

    await Promise.all(
      customers.map(customer =>
        api.post(
          `/v2/marketplaces/${marketplaceId}/charges`,
          {
            sellerId,
            buyerId: customer.id,
            description: chargeConfig.description,
            paymentTypesAccepted: paymentTypesAccepted,
            installments: Number(chargeConfig.installments),
            amount: amount.getAmount(),
            lastAmount,
            currency: 'BRL',
            paymentLimitDate: chargeConfig.paymentLimitDate,
            emailEnabled: chargeConfig.notification.emailEnabled,
          },
          {
            auth: {
              username: process.env.REACT_APP_BYPASS_API_KEY!,
              password: '',
            },
          }
        )
      )
    );

    enqueueSnackbar('Cobrança(s) criada(s) com sucesso');
    history.push('/charges');
  }

  function handleNextStep() {
    switch (step) {
      case 'select-customers':
        if (customers.length === 0) {
          enqueueSnackbar('Selecione ao menos um cliente', {
            variant: 'warning',
          });
          return;
        }

        setStep('charge-configuration');
        break;
      case 'charge-configuration':
        const amount = Dinero({
          amount: Number(chargeConfig.amount),
          currency: 'BRL',
        });
        const fiveDinero = Dinero({ amount: 1000, currency: 'BRL' });

        if (amount.lessThan(fiveDinero)) {
          enqueueSnackbar('O valor mínimo da cobrança é de R$ 10,00', {
            variant: 'warning',
          });
          return;
        }

        if (chargeConfig.installments === '') {
          enqueueSnackbar('Informe a quantidade de parcelas', {
            variant: 'warning',
          });
          return;
        }

        if (
          chargeConfig.paymentLimitDate === null ||
          !moment(chargeConfig.paymentLimitDate).isValid()
        ) {
          enqueueSnackbar('Informe a data de vencimento', {
            variant: 'warning',
          });
          return;
        }

        if (chargeConfig.description === '') {
          enqueueSnackbar('Informe a descrição da cobrança', {
            variant: 'warning',
          });
          return;
        }

        if (
          !chargeConfig.card.enabled &&
          !chargeConfig.boleto.enabled &&
          !chargeConfig.pix.enabled
        ) {
          enqueueSnackbar('Selecione ao menos uma forma de pagamento', {
            variant: 'warning',
          });
          return;
        }

        setStep('review');
        break;
      case 'review':
        handleCreateCharge();
        break;
      default:
    }
  }

  return (
    <Grow in>
      <Card className={classes.root}>
        {step === 'select-customers' && (
          <CustomerList
            addCustomer={addCustomer}
            addManyCustomers={addManyCustomers}
            customersSelected={customers}
            removeCustomer={removeCustomer}
            removeManyCustomers={removeManyCustomers}
          />
        )}
        {step === 'charge-configuration' && (
          <ChargeConfig
            basic={{
              paymentLimitDate: {
                onChange: handlePaymentLimitDateChange,
                value: chargeConfig.paymentLimitDate,
              },
              amount: {
                onChange: handleAmountChange,
                value: chargeConfig.amount,
              },
              description: {
                onChange: handleDescriptionChange,
                value: chargeConfig.description,
              },
              installments: {
                onChange: handleInstallmentsChange,
                value: chargeConfig.installments,
              },
            }}
            boleto={{
              enabled: chargeConfig.boleto.enabled,
              onEnableChange: handleEnabledBoletoToggle,
            }}
            card={{
              enabled: chargeConfig.card.enabled,
              onEnableChange: handleEnabledCardToggle,
            }}
            notification={{
              email: {
                enabled: chargeConfig.notification.emailEnabled,
                onEnableChange: handleEnabledSendEmailToggle,
              },
              whatsapp: {
                enabled: chargeConfig.notification.whatsappEnabled,
                onEnableChange: handleEnabledSendWhatsappToggle,
              },
            }}
            pix={{
              enabled: chargeConfig.pix.enabled,
              onEnableChange: handleEnabledPixToggle,
            }}
          />
        )}
        {step === 'review' && (
          <ReviseCharge
            amount={chargeConfig.amount}
            boleto={{
              enabled: chargeConfig.boleto.enabled,
            }}
            card={{
              enabled: chargeConfig.card.enabled,
            }}
            customers={customers}
            description={chargeConfig.description}
            installments={chargeConfig.installments}
            notification={{
              email: chargeConfig.notification.emailEnabled,
              whatsapp: chargeConfig.notification.whatsappEnabled,
            }}
            paymentLimitDate={chargeConfig.paymentLimitDate!}
            pix={{
              enabled: chargeConfig.pix.enabled,
            }}
          />
        )}
        <CardActions>
          <Grid
            alignItems="center"
            className={classes.containerCardActions}
            container
          >
            <Button
              className={classes.buttonPrevious}
              color="primary"
              disabled={step === 'select-customers'}
              onClick={handlePreviousStep}
              variant="outlined"
            >
              Voltar
            </Button>
            <Stepper
              activeStep={stepNumberMapper.get(step)?.number || 0}
              alternativeLabel
              className={classes.stepper}
            >
              {Array.from(stepNumberMapper).map(([step, { label }]) => (
                <Step key={step}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Button
              className={classes.buttonNext}
              color="primary"
              onClick={handleNextStep}
              variant="contained"
            >
              {step === 'select-customers' && 'Avançar'}
              {step === 'charge-configuration' && 'Revisar'}
              {step === 'review' && 'Criar cobrança(s)'}
            </Button>
          </Grid>
        </CardActions>
      </Card>
    </Grow>
  );
}
