import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(2),
  },
  toolbarHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardAppBar: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  cardContainer: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

export default useStyles;
