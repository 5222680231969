enum EBrowserEventEnum {
  CHARGE_CLEAR_FILTERS = 'charge.clear-filters',
}

const eventKey = '@fpay-backoffice:';

type TBrowserEventName = `${typeof eventKey}${EBrowserEventEnum}`;

export class BrowserEvent {
  private static debug = false;

  public static create<T = unknown>(eventName: TBrowserEventName, detail?: T) {
    return new CustomEvent(eventName, { detail });
  }

  public static dispatch<T = unknown>(event: CustomEvent<T>) {
    if (this.debug) {
      console.debug(`events[emit]: ${event.type}`, event.detail);
    }

    document.dispatchEvent(event);
  }

  public static addListener<T = unknown>(
    eventName: TBrowserEventName,
    listener: (event: CustomEvent<T>) => void
  ) {
    if (this.debug) {
      console.debug(`events[on]: ${eventName}`);
    }

    document.addEventListener(eventName, listener as EventListener);
  }

  public static removeListener<T = unknown>(
    eventName: TBrowserEventName,
    listener: (event: CustomEvent<T>) => void
  ) {
    if (this.debug) {
      console.debug(`events[off]: ${eventName}`);
    }

    document.removeEventListener(eventName, listener as EventListener);
  }
}
