import * as Mui from '@material-ui/core';
import React from 'react';
import type { IHttpPagination } from '../../../../../types/services/resource-list.types';
import type { IHttpPopulateCharge } from '../../../../../types/entities/charge-entity';
import useStyles from './charge-view-dialog-table.styles';
import { ChargeViewDialogCell } from '../charge-view-dialog-cell.component';
import { useSearchParams } from '../../../../../hooks/use-search-params.hook';
import { ChargeViewDialogTableRow } from './charge-view-dialog-table-row.component';
import { Skeleton } from '@material-ui/lab';
import { EChargeViewDialogSearchParam } from '../../charge-view-dialog.types';

export interface IChargeViewDialogTableColumn<T> {
  id: keyof IHttpPopulateCharge;
  label: string;
  align: 'right' | 'left' | 'center';
  cellComponent: React.FC<{ value: T }>;
}

interface IChargeViewDialogTableProps {
  chargeList: IHttpPagination<IHttpPopulateCharge>;
  isLoadingChargeList: boolean;
}

export function ChargeViewDialogTable(props: IChargeViewDialogTableProps) {
  const { chargeList, isLoadingChargeList } = props;

  const classes = useStyles();

  const searchParams = useSearchParams();

  function handleChangePage(
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) {
    searchParams.add(EChargeViewDialogSearchParam.PAGE, String(page + 1));
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    searchParams.add(EChargeViewDialogSearchParam.LIMIT, event.target.value);
  }

  const columns: IChargeViewDialogTableColumn<IHttpPopulateCharge>[] =
    React.useMemo(
      () => [
        {
          id: 'id',
          label: 'ID da cobrança',
          align: 'left',
          cellComponent: ChargeViewDialogCell.Id,
        },
        {
          id: 'groupChargeId',
          label: 'ID do grupo da cobrança',
          align: 'left',
          cellComponent: ChargeViewDialogCell.GroupId,
        },
        {
          id: 'description',
          label: 'Descrição',
          align: 'left',
          cellComponent: ChargeViewDialogCell.Description,
        },
        {
          id: 'paymentTypesAccepted',
          label: 'Tipos de pagamento',
          align: 'right',
          cellComponent: ChargeViewDialogCell.PaymentTypesAccepted,
        },
        {
          id: 'installment',
          label: 'Nº cobrança',
          align: 'right',
          cellComponent: ChargeViewDialogCell.Installment,
        },
        {
          id: 'paymentLimitDate',
          label: 'Data de vencimento',
          align: 'right',
          cellComponent: ChargeViewDialogCell.PaymentLimitDate,
        },
        {
          id: 'amount',
          label: 'Valor',
          align: 'right',
          cellComponent: ChargeViewDialogCell.Amount,
        },
        {
          id: 'status',
          label: 'Status',
          align: 'left',
          cellComponent: ChargeViewDialogCell.Status,
        },
        {
          id: 'paidAt',
          label: 'Data do pagamento',
          align: 'right',
          cellComponent: ChargeViewDialogCell.PaidAt,
        },
        {
          id: 'refundedAt',
          label: 'Data do reembolso',
          align: 'right',
          cellComponent: ChargeViewDialogCell.RefundedAt,
        },
        {
          id: 'emailEnabled',
          label: 'Envio por e-mail',
          align: 'left',
          cellComponent: ChargeViewDialogCell.EmailEnabled,
        },
        {
          id: 'emailStatus',
          label: 'Status do e-mail',
          align: 'left',
          cellComponent: ChargeViewDialogCell.EmailStatus,
        },
        {
          id: 'emailSendAt',
          label: 'Data do envio do e-mail',
          align: 'right',
          cellComponent: ChargeViewDialogCell.EmailSendAt,
        },
        {
          id: 'emailViewedAt',
          label: 'Data de visualização do e-mail',
          align: 'right',
          cellComponent: ChargeViewDialogCell.EmailViewedAt,
        },
        {
          id: 'createdAt',
          label: 'Data de criação',
          align: 'right',
          cellComponent: ChargeViewDialogCell.CreatedAt,
        },
        {
          id: 'updatedAt',
          label: 'Data de atualização',
          align: 'right',
          cellComponent: ChargeViewDialogCell.UpdatedAt,
        },
      ],
      []
    );

  return (
    <Mui.Paper className={classes.paper}>
      {!isLoadingChargeList ? (
        <>
          <Mui.TablePagination
            backIconButtonText="Voltar página"
            component="div"
            count={chargeList.total}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${
                count !== -1 ? count : `mais que ${to}`
              }`
            }
            labelRowsPerPage=""
            nextIconButtonText="Próxima página"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={chargeList.page - 1}
            rowsPerPage={chargeList.limit}
            rowsPerPageOptions={[30, 50, 100]}
          />
          <Mui.TableContainer>
            <Mui.Table
              aria-label="Lista de cobrança"
              className={classes.table}
              stickyHeader
            >
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell
                    className={classes.tableTh}
                    padding="checkbox"
                  />
                  {columns.map(column => (
                    <Mui.TableCell
                      align={column.align}
                      className={classes.tableTh}
                      key={column.id}
                    >
                      {column.label}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {chargeList.items.map(charge => (
                  <ChargeViewDialogTableRow
                    charge={charge}
                    columns={columns}
                    key={charge.id}
                  />
                ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.TablePagination
            backIconButtonText="Voltar página"
            component="div"
            count={chargeList.total}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to === -1 ? count : to} de ${
                count !== -1 ? count : `mais que ${to}`
              }`
            }
            labelRowsPerPage=""
            nextIconButtonText="Próxima página"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={chargeList.page - 1}
            rowsPerPage={chargeList.limit}
            rowsPerPageOptions={[30, 50, 100]}
          />{' '}
        </>
      ) : (
        <Skeleton height={300} variant="rect" />
      )}
    </Mui.Paper>
  );
}
