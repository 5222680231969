import type {
  IChargeMetrics,
  IHttpPopulateChargeMetrics,
} from '../types/entities/charge-metrics-entity';
import Dinero from 'dinero.js';
import moment from 'moment-timezone';
import { ChargeUtils } from './charge-utils';
import numeral from 'numeral';

export class ChargeMetricsUtils {
  public static statusLabelMap = new Map<IChargeMetrics.TStatus, string>([
    ['current', 'Em dia'],
    ['overdue', 'Atrasada'],
    ['paid', 'Pago'],
  ]);

  public static toStatusLabel(status: IChargeMetrics.TStatus): string {
    if (!ChargeMetricsUtils.statusLabelMap.has(status)) {
      return status;
    }

    return ChargeMetricsUtils.statusLabelMap.get(status)!;
  }

  public static formatTotalAmount(amount: number): string {
    const objectAmount = Dinero({ amount: amount, currency: 'BRL' }).setLocale(
      'pt-BR'
    );

    return objectAmount.toFormat();
  }

  public static formatTotalAmountOverdue(amount: number): string {
    const objectAmount = Dinero({ amount: amount, currency: 'BRL' }).setLocale(
      'pt-BR'
    );

    return objectAmount.toFormat();
  }

  public static formatTotalAmountPaid(amount: number): string {
    const objectAmount = Dinero({ amount: amount, currency: 'BRL' }).setLocale(
      'pt-BR'
    );

    return objectAmount.toFormat();
  }

  public static formatTotalAmountPending(amount: number): string {
    const objectAmount = Dinero({ amount: amount, currency: 'BRL' }).setLocale(
      'pt-BR'
    );

    return objectAmount.toFormat();
  }

  public static formatPercentagePaid(percentagePaid: number): string {
    return `${numeral(percentagePaid).multiply(100).value()}%`;
  }

  public static formatPercentagePending(percentagePending: number): string {
    return `${numeral(percentagePending).multiply(100).value()}%`;
  }

  public static formatPercentageOverdue(percentageOverdue: number): string {
    return `${numeral(percentageOverdue).multiply(100).value()}%`;
  }

  public static formatNextDueDate(nextDueDate: string | null): string | null {
    if (!nextDueDate) {
      return null;
    }

    return moment.utc(nextDueDate).format('DD/MM/YYYY');
  }

  public static formatLastPaymentDate(
    lastPaymentDate: string | null
  ): string | null {
    if (!lastPaymentDate) {
      return null;
    }

    return moment.utc(lastPaymentDate).format('DD/MM/YYYY');
  }

  public static formatLastPaymentMethodUsed(
    lastPaymentMethodUsed: IHttpPopulateChargeMetrics['lastPaymentMethodUsed']
  ): string | null {
    if (!lastPaymentMethodUsed) {
      return null;
    }

    return ChargeUtils.toPaymentTypeLabel(lastPaymentMethodUsed);
  }
}
