import React from 'react';
import * as Mui from '@material-ui/core';
import useStyles from './charge-config.styles';
import { ChargeSendConfig } from './charge-send-config.component';
import { ChargeDiscountConfig } from './charge-discount-config.component';
import { ChargeDueConfig } from './charge-due-config.component';
import { ChargePixConfig } from './charge-pix-config.component';
import { ChargeCardConfig } from './charge-card-config.component';
import { ChargeBoletoConfig } from './charge-boleto-config.component';
import { ChargeBasicConfig } from './charge-basic-config.component';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface IChargeConfigProps {
  basic: {
    paymentLimitDate: {
      value: string | null;
      onChange: (momentDate: MaterialUiPickersDate) => void;
    };
    amount: {
      value: string;
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
    description: {
      value: string;
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
    installments: {
      value: string;
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    };
  };
  pix: {
    onEnableChange: (enabled: boolean) => void;
    enabled: boolean;
  };
  boleto: {
    onEnableChange: (enabled: boolean) => void;
    enabled: boolean;
  };
  card: {
    onEnableChange: (enabled: boolean) => void;
    enabled: boolean;
  };
  notification: {
    email: {
      onEnableChange: (enabled: boolean) => void;
      enabled: boolean;
    };
    whatsapp: {
      onEnableChange: (enabled: boolean) => void;
      enabled: boolean;
    };
  };
}

export function ChargeConfig(props: IChargeConfigProps) {
  const { basic, pix, card, boleto, notification } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Mui.CardHeader
        subheader={
          <Mui.Typography
            className={classes.subTitle}
            color="textSecondary"
            variant="h5"
          >
            Configure valor, formas de pagamento, parcelamento e data de
            vencimento.
          </Mui.Typography>
        }
        title={
          <Mui.Typography variant="h3">Informações de pagamento</Mui.Typography>
        }
      />
      <div className={classes.configContainer}>
        <Mui.Divider />
        <ChargeBasicConfig {...basic} />
        <Mui.Divider />
        <ChargePixConfig {...pix} />
        <Mui.Divider />
        <ChargeCardConfig {...card} />
        <Mui.Divider />
        <ChargeBoletoConfig {...boleto} />
        <Mui.Divider />
        <ChargeDueConfig />
        <Mui.Divider />
        <ChargeDiscountConfig />
        <Mui.Divider />
        <ChargeSendConfig {...notification} />
      </div>
    </React.Fragment>
  );
}
