import { PersonDocumentUtils } from './person-document-utils';
import { PhoneNumberUtils } from './phone-number-utils';
import moment from 'moment';
import { EntityUtils } from './entity-utils';

export class BuyerUtils extends EntityUtils {
  public static formatDocument(document: string): string {
    return PersonDocumentUtils.format(document);
  }

  public static formatPhoneNumber(phoneNumber: string): string {
    return PhoneNumberUtils.format(phoneNumber);
  }

  public static formatBirthdate(birthdate: string): string {
    return moment(birthdate).format('DD/MM/YYYY');
  }
}
