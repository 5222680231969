import React from 'react';
import type { TTableListState } from '../../../../../components/data-display/table/table.types';
import { TableLoading } from '../../../../../components/data-display/table/implementations/table-loading.component';
import { TableEmptyStateInitial } from '../../../../../components/data-display/table/implementations/table-empty-state-initial.component';
import { TableFailedState } from '../../../../../components/data-display/table/implementations/table-failed-state.component';
import { ChargeTableList } from './charge-list-table.component';
import type { IHttpPopulateCharge } from '../../../../../types/entities/charge-entity';
import { TableEmptyStateFiltered } from '../../../../../components/data-display/table/implementations/table-empty-state-filtered.component';

interface IChargeListTableFactoryProps {
  charges: IHttpPopulateCharge[];
  pagination: {
    limit: number;
    page: number;
    total: number;
  };
  state: TTableListState;
  onClearFilters: () => void;
  onCreate: () => void;
  onRefresh: () => void;
}

export function ChargeListTableFactory(props: IChargeListTableFactoryProps) {
  const { state, charges, pagination, onCreate, onRefresh, onClearFilters } =
    props;

  switch (state) {
    case 'loading':
      return <TableLoading />;
    case 'empty':
      return (
        <TableEmptyStateInitial
          buttonLabel="Crie uma agora"
          label="Você ainda não possui nenhuma cobrança!"
          onCreate={onCreate}
        />
      );
    case 'failed':
      return <TableFailedState onRefresh={onRefresh} />;
    case 'empty-filtered':
      return <TableEmptyStateFiltered onClearFilters={onClearFilters} />;
    case 'list':
      return <ChargeTableList charges={charges} pagination={pagination} />;
    default:
      return null;
  }
}
