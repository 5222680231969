import { useHistory } from 'react-router-dom';
import React from 'react';

export function useSearchParams() {
  const history = useHistory();

  const has = React.useCallback(key => {
    const currentParams = new URLSearchParams(window.location.search);

    return currentParams.has(key);
  }, []);

  const set = React.useCallback(
    params => {
      const newSearchParams = new URLSearchParams(params);

      history.push({
        search: newSearchParams.toString(),
      });
    },
    [history]
  );

  const add = React.useCallback(
    (key, value) => {
      const currentParams = new URLSearchParams(window.location.search);

      currentParams.set(key, value);

      set(currentParams);
    },
    [set]
  );

  const remove = React.useCallback(
    key => {
      const currentParams = new URLSearchParams(window.location.search);

      currentParams.delete(key);

      set(currentParams);
    },
    [set]
  );

  const clear = React.useCallback(() => {
    const currentParams = new URLSearchParams(window.location.search);

    currentParams.forEach((_, key) => currentParams.delete(key));

    set(currentParams);
  }, [set]);

  return React.useMemo(
    () => ({ add, set, remove, clear, has }),
    [add, set, remove, clear, has]
  );
}
