import * as Mui from '@material-ui/core';
import { ChargeMetricsUtils } from '../../../../helpers/charge-metrics-utils';
import React from 'react';
import type { IHttpPopulateChargeMetrics } from '../../../../types/entities/charge-metrics-entity';
import useStyles from './charge-view-dialog-analytics.styles';
import { OverviewCard } from '../../../../components/data-display/overview-card/overview-card.component';

interface IChargeViewDialogAnalyticsProps {
  chargeMetrics: IHttpPopulateChargeMetrics | null;
  isLoadingChargeMetrics: boolean;
}

export function ChargeViewDialogAnalytics(
  props: IChargeViewDialogAnalyticsProps
) {
  const { chargeMetrics, isLoadingChargeMetrics } = props;

  const classes = useStyles();

  return (
    <div>
      <Mui.AppBar
        className={classes.cardAppBar}
        color="secondary"
        position="static"
      >
        <Mui.Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <Mui.Typography variant="h5">Analytics / Métricas</Mui.Typography>
          </div>
        </Mui.Toolbar>
      </Mui.AppBar>
      <div className={classes.cardContainer}>
        <Mui.Grid container spacing={2}>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={ChargeMetricsUtils.formatTotalAmount(
                chargeMetrics?.totalAmount || 0
              )}
              title="Valor total"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={ChargeMetricsUtils.formatTotalAmountPaid(
                chargeMetrics?.totalAmountPaid || 0
              )}
              title="Valor total pago"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={ChargeMetricsUtils.formatTotalAmountPending(
                chargeMetrics?.totalAmountPending || 0
              )}
              title="Valor total pendente"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={ChargeMetricsUtils.formatTotalAmountOverdue(
                chargeMetrics?.totalAmountOverdue || 0
              )}
              title="Valor total atrasado"
            />
          </Mui.Grid>

          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={chargeMetrics?.totalCharges || 0}
              title="N° de cobranças"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={chargeMetrics?.totalChargesPaid || 0}
              title="N° de cobranças pagas"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={chargeMetrics?.totalChargesPending || 0}
              title="N° de cobranças pendentes"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={chargeMetrics?.totalChargesOverdue || 0}
              title="N° de cobranças atrasadas"
            />
          </Mui.Grid>

          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={ChargeMetricsUtils.formatPercentagePaid(
                chargeMetrics?.percentagePaid || 0
              )}
              title="Total pago (%)"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={ChargeMetricsUtils.formatPercentagePending(
                chargeMetrics?.percentagePending || 0
              )}
              title="Total pendente (%)"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} sm={3} xs={12}>
            <OverviewCard
              failed={false}
              loaded={!isLoadingChargeMetrics}
              onRefresh={() => alert('Atualizar')}
              text={ChargeMetricsUtils.formatPercentageOverdue(
                chargeMetrics?.percentageOverdue || 0
              )}
              title="Total atrasado (%)"
            />
          </Mui.Grid>
        </Mui.Grid>
      </div>
    </div>
  );
}
