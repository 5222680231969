import * as Mui from '@material-ui/core';
import { CopyToClipboardButton } from '../../../../components/fields/copy-to-clipboard-button/copy-to-clipboard-button.component';
import { BuyerUtils } from '../../../../helpers/buyer-utils';
import React from 'react';
import type { IHttpBuyer } from '../../../../types/entities/buyer';

import useStyles from './charge-view-dialog-buyer-info.styles';

interface IChargeViewDialogBuyerInfoProps {
  buyer: IHttpBuyer;
}

export function ChargeViewDialogBuyerInfo(
  props: IChargeViewDialogBuyerInfoProps
) {
  const { buyer } = props;

  const classes = useStyles();

  return (
    <div>
      <Mui.AppBar
        className={classes.cardAppBar}
        color="secondary"
        position="static"
      >
        <Mui.Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <Mui.Typography variant="h5">Informações do cliente</Mui.Typography>
          </div>
        </Mui.Toolbar>
      </Mui.AppBar>
      <div className={classes.cardContainer}>
        <Mui.Grid container spacing={2}>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">ID do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.id}>
              {BuyerUtils.shortUuidV4(buyer.id)}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Nome do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.name}>
              {buyer.name}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">CPF/CNPJ do cliente</Mui.Typography>
            <CopyToClipboardButton
              textToCopy={BuyerUtils.formatDocument(buyer.documentNumber)}
            >
              {BuyerUtils.formatDocument(buyer.documentNumber)}
            </CopyToClipboardButton>
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">E-mail do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.email || ''}>
              {buyer.email || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Contato do cliente</Mui.Typography>
            <CopyToClipboardButton
              textToCopy={
                buyer.phoneNumber
                  ? BuyerUtils.formatPhoneNumber(buyer.phoneNumber)
                  : ''
              }
            >
              {buyer.phoneNumber
                ? BuyerUtils.formatPhoneNumber(buyer.phoneNumber)
                : 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Data de nascimento do cliente
            </Mui.Typography>
            <CopyToClipboardButton
              textToCopy={
                buyer.birthdate
                  ? BuyerUtils.formatBirthdate(buyer.birthdate)
                  : ''
              }
            >
              {buyer.birthdate
                ? BuyerUtils.formatBirthdate(buyer.birthdate)
                : 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">CEP do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.postalCode || ''}>
              {buyer.postalCode || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Logradouro do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.street || ''}>
              {buyer.street || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Número do Logradouro do cliente
            </Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.houseNumber || ''}>
              {buyer.houseNumber || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Bairro</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.neighborhood || ''}>
              {buyer.neighborhood || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Cidade do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.city || ''}>
              {buyer.city || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Estado do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.state || ''}>
              {buyer.state || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">País do cliente</Mui.Typography>
            <CopyToClipboardButton textToCopy={buyer.countryCode || ''}>
              {buyer.countryCode || 'Não cadastrado'}
            </CopyToClipboardButton>
          </Mui.Grid>
        </Mui.Grid>
      </div>
    </div>
  );
}
