import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  appBarActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  title: {
    flex: 1,
    color: '#fff',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    width: '100%',
    backgroundColor: theme.palette.background.default,
    gap: theme.spacing(2),
  },
}));

export default useStyles;
