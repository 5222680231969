import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buyerListItemText: {
    margin: 0,
  },
  totalChargesSkeleton: {
    margin: 'auto',
  },
}));

export default useStyles;
