export class PhoneNumberUtils {
  public static format(phoneNumber: string): string {
    const type = PhoneNumberUtils.phoneNumberType(phoneNumber);
    const cleaned = PhoneNumberUtils.clean(phoneNumber);

    switch (type) {
      case 'fixed':
        return PhoneNumberUtils.formatFixed(cleaned);
      case 'cellphone':
        return PhoneNumberUtils.formatCellphone(cleaned);
      default:
        return cleaned;
    }
  }

  public static phoneNumberType(phoneNumber: string): string {
    const cleaned = phoneNumber.replace(/\D/g, '');

    if (cleaned.length === 10) {
      return 'fixed';
    } else if (cleaned.length === 11) {
      return 'cellphone';
    } else {
      return 'unknown';
    }
  }

  public static formatFixed(phoneNumber: string): string {
    return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(
      2,
      6
    )}-${phoneNumber.substring(6, 10)}`;
  }

  public static formatCellphone(phoneNumber: string): string {
    return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(
      2,
      7
    )}-${phoneNumber.substring(7, 11)}`;
  }

  public static clean(phoneNumber: string): string {
    return phoneNumber.replace(/\D/g, '');
  }
}
