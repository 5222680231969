import React from 'react';
import { DialogScreenManagerContext } from './dialog-screen-manager.context';
import { DialogScreenItem } from './dialog-screen-item.component';

export type TDialogScreenManagerStackDialogId = number;

export interface IDialogScreenManagerProviderEnqueueOptionsProps {
  fullScreen?: boolean;
  onClose?: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
}

export interface IDialogScreenManagerProviderDialogsState {
  content: React.ReactNode;
  fullScreen: boolean;
  fullWidth: boolean;
  id: number;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  open: boolean;
  onClose: () => void;
}

interface IDialogScreenManagerProviderProps {
  children?: React.ReactNode;
}

export function DialogScreenManagerProvider(
  props: IDialogScreenManagerProviderProps
) {
  const { children } = props;

  const [dialogs, setDialogs] = React.useState<
    IDialogScreenManagerProviderDialogsState[]
  >([]);

  const stackDialog = React.useCallback(
    (
      content: React.ReactNode,
      options?: IDialogScreenManagerProviderEnqueueOptionsProps
    ): TDialogScreenManagerStackDialogId => {
      const fullScreen = options?.fullScreen || false;
      const fullWidth = options?.fullWidth || false;
      const id = new Date().getTime() + Math.random();
      const maxWidth = options?.maxWidth || 'sm';
      const open = true;
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const onClose = options?.onClose || (() => {});

      setDialogs(prev => {
        return [
          ...prev,
          {
            content,
            fullScreen,
            fullWidth,
            id,
            maxWidth,
            open,
            onClose,
          },
        ];
      });

      return id;
    },
    []
  );

  function handleClose(id: TDialogScreenManagerStackDialogId) {
    setDialogs(prev => {
      return prev.filter(dialog => {
        if (dialog.id === id) {
          dialog.onClose();
          return false;
        }

        return true;
      });
    });
  }

  return (
    <DialogScreenManagerContext.Provider value={{ stackDialog }}>
      {children}
      {dialogs.map(dialog => (
        <DialogScreenItem
          content={dialog.content}
          fullScreen={dialog.fullScreen}
          fullWidth={dialog.fullWidth}
          id={dialog.id}
          key={dialog.id}
          maxWidth={dialog.maxWidth}
          onClose={handleClose}
          open={dialog.open}
        />
      ))}
    </DialogScreenManagerContext.Provider>
  );
}
