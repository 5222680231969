import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)} 0`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
  },
  leftActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    order: 3,
    gap: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      order: 1,
      width: 'auto',
    },
  },
  rightActionsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    order: 1,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      flexDirection: 'row',
      order: 3,
      width: 'auto',
    },
  },
  spacer: {
    order: 2,
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginTop: theme.spacing(1),
    order: 2,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      order: 1,
      marginTop: 0,
      marginRight: theme.spacing(1),
      width: 'auto',
    },
  },
  searchInput: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
  },
  buttonCreate: {
    order: 1,
    marginTop: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: 0,
      order: 2,
    },
  },

  // Pickers
  rootDialog: {
    backgroundColor: theme.palette.background.dark2,
  },
  dateStartPicker: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  dateEndPicker: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  buttonPicker: {
    color: theme.palette.white,
    backgroundColor: 'inherit',
  },
}));

export default useStyles;
