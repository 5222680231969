import * as Mui from '@material-ui/core';
import React from 'react';
import type { IHttpPopulateCharge } from '../../../../types/entities/charge-entity';

import useStyles from './charge-view-dialog-notification-info.styles';

interface IChargeViewDialogNotificationInfoProps {
  charge: IHttpPopulateCharge;
}

export function ChargeViewDialogNotificationInfo(
  props: IChargeViewDialogNotificationInfoProps
) {
  const { charge } = props;

  const classes = useStyles();

  return (
    <div>
      <Mui.AppBar
        className={classes.cardAppBar}
        color="secondary"
        position="static"
      >
        <Mui.Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <Mui.Typography variant="h5">
              Informações das configurações de notificação
            </Mui.Typography>
          </div>
        </Mui.Toolbar>
      </Mui.AppBar>
      <div className={classes.cardContainer}>
        <Mui.Grid container spacing={2}>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Envio por e-mail ativo</Mui.Typography>
            <Mui.Typography>
              {charge.emailEnabled ? 'Sim' : 'Não'}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Envio por Whatsapp ativo
            </Mui.Typography>
            <Mui.Typography>Não</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Envio por SMS ativo</Mui.Typography>
            <Mui.Typography>Não</Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </div>
    </div>
  );
}
