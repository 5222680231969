import { useQuery } from '@tanstack/react-query';
import { api } from '../../services/api';
import type { IHttpPopulateChargeMetrics } from '../../types/entities/charge-metrics-entity';
import React from 'react';

interface IUserChargeMetricsProps {
  marketplaceId: string;
  chargeId: string;
}

export function useChargeMetrics(props: IUserChargeMetricsProps) {
  const { marketplaceId, chargeId } = props;

  const {
    data = null,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['charges-metrics', marketplaceId, chargeId],
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity,
    queryFn: async () => {
      const response = await api.get<IHttpPopulateChargeMetrics>(
        `/v2/marketplaces/${marketplaceId}/charges/${chargeId}/metrics`,
        {
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
        }
      );

      return response.data;
    },
  });

  return React.useMemo(
    () => ({
      chargeMetrics: data,
      isFetchingChargeMetrics: isFetching,
      isLoadingChargeMetrics: isLoading,
      refetchChargeMetrics: refetch,
    }),
    [data, isFetching, isLoading, refetch]
  );
}
