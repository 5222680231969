import React from 'react';
import * as Mui from '@material-ui/core';

import useStyles from './table-empty-state-initial.styles';

interface ITableEmptyStateInitialProps {
  label: React.ReactNode;
  buttonLabel: React.ReactNode;
  onCreate: () => void;
}

export function TableEmptyStateInitial(props: ITableEmptyStateInitialProps) {
  const { label, buttonLabel, onCreate } = props;

  const classes = useStyles();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        {label}
      </Mui.Typography>
      <Mui.Button
        className={classes.button}
        color="primary"
        disableFocusRipple
        disableRipple
        onClick={onCreate}
        variant="text"
      >
        {buttonLabel}
      </Mui.Button>
    </Mui.Grid>
  );
}
