import React from 'react';
import * as Mui from '@material-ui/core';

import useStyles from './table-empty-state-filtered.styles';

interface ITableEmptyStateFilteredProps {
  onClearFilters: () => void;
}

export function TableEmptyStateFiltered(props: ITableEmptyStateFilteredProps) {
  const { onClearFilters } = props;

  const classes = useStyles();

  return (
    <Mui.Grid className={classes.container} container>
      <Mui.Typography className={classes.text} variant="h5">
        Não encontramos nenhum cliente com este filtro!
      </Mui.Typography>
      <Mui.Button
        className={classes.button}
        color="secondary"
        disableFocusRipple
        disableRipple
        onClick={onClearFilters}
        variant="contained"
      >
        Limpar filtro
      </Mui.Button>
    </Mui.Grid>
  );
}
