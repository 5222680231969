import React from 'react';
import { DialogScreenManagerContext } from './dialog-screen-manager.context';

export function useDialogScreenManager() {
  const context = React.useContext(DialogScreenManagerContext);

  if (!context) {
    throw new Error(
      'useSnackbar must be used within a DialogScreenManagerProvider'
    );
  }

  return context;
}
