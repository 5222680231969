import { useSearchParams } from '../../../hooks/use-search-params.hook';
import { useLoadingBar } from 'react-top-loading-bar';
import { PaginationFactory } from '../../../factories/pagination-factory';
import { useQuery } from '@tanstack/react-query';
import { Pagination } from '../../../entities/pagination';
import { api } from '../../../services/api';
import type { IHttpPagination } from '../../../types/services/resource-list.types';
import type { IHttpPopulateCharge } from '../../../types/entities/charge-entity';
import React from 'react';
import { useChargeMetrics } from '../../../hooks/use-charge/use-charge-metrics.hook';
import { useLocation } from 'react-router-dom';
import { EChargeViewDialogSearchParam } from './charge-view-dialog.types';

interface IUserChargeViewDialogProps {
  marketplaceId: string;
  charge: IHttpPopulateCharge;
  onClose?: () => void;
}

export function useChargeViewDialog(props: IUserChargeViewDialogProps) {
  const { marketplaceId, charge, onClose } = props;

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const searchParams = useSearchParams();

  const page = params.get(EChargeViewDialogSearchParam.PAGE);
  const limit = params.get(EChargeViewDialogSearchParam.LIMIT);

  const pagination = React.useMemo(() => {
    const pageAsNumber = page ? Number(page) : null;
    const limitAsNumber = limit ? Number(limit) : null;

    return new Pagination({
      limit: limitAsNumber,
      page: pageAsNumber,
    });
  }, [limit, page]);

  const { start, complete } = useLoadingBar();

  const {
    data: chargeList = PaginationFactory.create(),
    isLoading: isLoadingChargeList,
    isFetching: isFetchingChargeList,
    refetch: refetchChargeList,
  } = useQuery({
    queryKey: [
      'charges-by-group',
      marketplaceId,
      charge.id,
      pagination.limit,
      pagination.offset,
    ],
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity,
    queryFn: async () => {
      const response = await api.get<IHttpPagination<IHttpPopulateCharge>>(
        `/v2/marketplaces/${marketplaceId}/charges/${charge.id}/groups`,
        {
          params: {
            limit: pagination.limit,
            offset: pagination.offset,
            populate: ['buyer'].join(','),
          },
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
        }
      );

      return response.data;
    },
  });

  const {
    refetchChargeMetrics,
    isLoadingChargeMetrics,
    isFetchingChargeMetrics,
    chargeMetrics,
  } = useChargeMetrics({
    chargeId: charge.id,
    marketplaceId,
  });

  const handleDialogClose = React.useCallback(() => {
    onClose?.();
  }, [onClose]);

  const refetch = React.useCallback(async () => {
    await Promise.all([refetchChargeList(), refetchChargeMetrics()]);
  }, [refetchChargeList, refetchChargeMetrics]);

  React.useEffect(() => {
    if (isFetchingChargeList || isFetchingChargeMetrics) {
      start();
    }

    if (!isFetchingChargeList && !isFetchingChargeMetrics) {
      complete();
    }
  }, [isFetchingChargeList, isFetchingChargeMetrics, start, complete]);

  React.useEffect(() => {
    if (!searchParams.has(EChargeViewDialogSearchParam.KEY)) {
      searchParams.add(EChargeViewDialogSearchParam.KEY, 'true');
    }

    if (!searchParams.has(EChargeViewDialogSearchParam.ID)) {
      searchParams.add(EChargeViewDialogSearchParam.ID, charge.id);
    }

    return () => {
      Object.values(EChargeViewDialogSearchParam).forEach(key => {
        searchParams.remove(key);
      });
    };
  }, [charge.id, searchParams]);

  return React.useMemo(
    () => ({
      chargeList,
      chargeMetrics,
      isLoadingChargeList,
      isLoadingChargeMetrics,
      handleDialogClose,
      refetch,
    }),
    [
      chargeList,
      chargeMetrics,
      isLoadingChargeList,
      isLoadingChargeMetrics,
      handleDialogClose,
      refetch,
    ]
  );
}
