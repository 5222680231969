import { Dialog } from '@material-ui/core';
import React from 'react';
import type { TDialogScreenManagerStackDialogId } from './dialog-screen-manager.component';

interface IDialogScreenItemProps {
  content: React.ReactNode;
  fullScreen: boolean;
  fullWidth: boolean;
  id: number;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  open: boolean;
  onClose: (id: TDialogScreenManagerStackDialogId) => void;
}

export function DialogScreenItem(props: IDialogScreenItemProps) {
  const { fullScreen, id, maxWidth, open, content, fullWidth, onClose } = props;

  function handleClose() {
    onClose(id);
  }

  const contentWithProps = React.Children.map(content, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClose: handleClose });
    }

    return child;
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
    >
      {contentWithProps}
    </Dialog>
  );
}
