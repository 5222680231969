import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import theme from './theme';
import history from 'hooks/useHistory';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './styles/globalStyles.css';
import validators from './common/validators';
import Routes from './Routes';
import { SnackbarLegacy } from './components';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { LoadingBarContainer } from 'react-top-loading-bar';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { SnackbarProvider } from './components/feedback/snackbar/snackbar.component';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
  key: '@fpay-backofficeV1:cache',
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <LoadingBarContainer
            props={{
              color: theme.palette.gradient.main,
            }}
          >
            <SnackbarProvider>
              <ReactQueryDevtools initialIsOpen={false} />
              <Router history={history}>
                <Routes />
                <SnackbarLegacy />
              </Router>
            </SnackbarProvider>
          </LoadingBarContainer>
        </PersistQueryClientProvider>
      </ThemeProvider>
    );
  }
}
