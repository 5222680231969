import React from 'react';

import type { IChargeTableListColumnValue } from './charge-list-table.component';
import { ChargeUtils } from '../../../../../helpers/charge-utils';
import { CopyToClipboardButton } from '../../../../../components/fields/copy-to-clipboard-button/copy-to-clipboard-button.component';
import {
  Avatar,
  Grid,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import getInitials from '../../../../../helpers/getInitials';
import { getDisplayEmail } from '../../../../../helpers';
import useStyles from './charge-list-table-cell.styles';
import { BuyerUtils } from '../../../../../helpers/buyer-utils';
import { ChargeMetricsUtils } from '../../../../../helpers/charge-metrics-utils';
import { Skeleton } from '@material-ui/lab';

interface IChargeTableListCellProps {
  value: IChargeTableListColumnValue;
}

function ChargeListTableCellId(props: IChargeTableListCellProps) {
  const { value } = props;
  const { charge } = value;

  return (
    <CopyToClipboardButton textToCopy={charge.id}>
      {ChargeUtils.shortUuidV4(charge.id)}
    </CopyToClipboardButton>
  );
}

function ChargeListTableCellBuyer(props: IChargeTableListCellProps) {
  const { value } = props;
  const { charge } = value;

  const classes = useStyles();

  return (
    <Grid container direction="row" wrap="nowrap">
      <ListItemAvatar>
        <Avatar>{getInitials(charge.buyer?.name)}</Avatar>
      </ListItemAvatar>

      <ListItemText
        className={classes.buyerListItemText}
        disableTypography
        primary={
          <CopyToClipboardButton textToCopy={charge.buyer?.name || ''}>
            <Typography component="span" variant="h5">
              {charge.buyer?.name}
            </Typography>
          </CopyToClipboardButton>
        }
        secondary={
          <Grid component="span" container direction="column">
            <Grid component="span" item xs zeroMinWidth>
              <CopyToClipboardButton textToCopy={charge.buyer?.email || ''}>
                {getDisplayEmail(charge.buyer?.email)}
              </CopyToClipboardButton>
            </Grid>
            <Grid component="span" item xs zeroMinWidth>
              <CopyToClipboardButton
                textToCopy={
                  charge.buyer?.phoneNumber
                    ? BuyerUtils.formatPhoneNumber(charge.buyer?.phoneNumber)
                    : ''
                }
              >
                {charge.buyer?.phoneNumber
                  ? BuyerUtils.formatPhoneNumber(charge.buyer?.phoneNumber)
                  : 'Sem número de contato'}
              </CopyToClipboardButton>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
}

function ChargeListTableCellStatus(props: IChargeTableListCellProps) {
  const { value } = props;
  const { chargeMetrics, isLoadingChargeMetrics } = value;

  return (
    <Typography>
      {chargeMetrics?.status &&
        ChargeMetricsUtils.toStatusLabel(chargeMetrics.status)}
      {isLoadingChargeMetrics && <Skeleton width="100%" />}
    </Typography>
  );
}

function ChargeListTableCellDescription(props: IChargeTableListCellProps) {
  const { value } = props;
  const { charge } = value;

  return <Typography>{charge.description}</Typography>;
}

function ChargeListTableCellTotalAmount(props: IChargeTableListCellProps) {
  const { value } = props;
  const { chargeMetrics, isLoadingChargeMetrics } = value;

  return (
    <Typography>
      {ChargeMetricsUtils.formatTotalAmount(chargeMetrics?.totalAmount || 0)}
      {isLoadingChargeMetrics && <Skeleton width="100%" />}
    </Typography>
  );
}

function ChargeListTableCellTotalAmountPaid(props: IChargeTableListCellProps) {
  const { value } = props;
  const { chargeMetrics, isLoadingChargeMetrics } = value;

  return (
    <Typography>
      {ChargeMetricsUtils.formatTotalAmountPaid(
        chargeMetrics?.totalAmountPaid || 0
      )}
      {isLoadingChargeMetrics && <Skeleton width="100%" />}
    </Typography>
  );
}

function ChargeListTableCellInstallments(props: IChargeTableListCellProps) {
  const { value } = props;
  const { chargeMetrics, isLoadingChargeMetrics } = value;

  const classes = useStyles();

  return (
    <Typography>
      {chargeMetrics?.totalCharges}
      {isLoadingChargeMetrics && (
        <Skeleton className={classes.totalChargesSkeleton} width="35%" />
      )}
    </Typography>
  );
}

export const ChargeListTableCell = {
  Id: ChargeListTableCellId,
  Buyer: ChargeListTableCellBuyer,
  Status: ChargeListTableCellStatus,
  Description: ChargeListTableCellDescription,
  TotalAmount: ChargeListTableCellTotalAmount,
  TotalAmountPaid: ChargeListTableCellTotalAmountPaid,
  Installments: ChargeListTableCellInstallments,
};
