import React from 'react';
import useChargeViewDialogStyles from '../../../../views/charges/charge-view-dialog/charge-view-dialog.styles';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../../../hooks/useAuthContext.hook';
import { api } from '../../../../services/api';
import type { IHttpPopulateCharge } from '../../../../types/entities/charge-entity';
import {
  AppBar,
  CircularProgress,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ChargeUtils } from '../../../../helpers/charge-utils';
import { Close, Refresh, Settings } from '@material-ui/icons';
import clsx from 'clsx';
import { ChargeViewDialog } from '../../../../views/charges/charge-view-dialog/charge-view-dialog.component';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IChargeViewDialogWrapperProps {
  chargeId: string;
  onClose?: () => void;
}

export function ChargeViewDialogWrapper(props: IChargeViewDialogWrapperProps) {
  const { onClose, chargeId } = props;

  const chargeViewDialogClasses = useChargeViewDialogStyles();
  const classes = useStyles();

  const { user } = useAuthContext();
  const marketplaceId = user.marketplace_id;

  const { data: charge = null, isLoading } = useQuery({
    queryKey: ['charges', marketplaceId, chargeId],
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity,
    queryFn: async () => {
      const response = await api.get<IHttpPopulateCharge>(
        `/v2/marketplaces/${marketplaceId}/charges/${chargeId}`,
        {
          params: {
            populate: ['buyer'].join(','),
          },
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
        }
      );

      return response.data;
    },
  });

  if (isLoading || !charge) {
    return (
      <>
        <AppBar className={chargeViewDialogClasses.appBar} color="default">
          <Toolbar>
            <Typography className={chargeViewDialogClasses.title} variant="h5">
              {`Detalhes da cobrança (${ChargeUtils.shortUuidV4(chargeId)})`}
            </Typography>
            <div className={chargeViewDialogClasses.appBarActionsContainer}>
              <IconButton
                aria-label="Configurações"
                color="inherit"
                disabled
                edge="end"
              >
                <Settings />
              </IconButton>
              <IconButton
                aria-label="Atualizar"
                color="inherit"
                disabled
                edge="end"
              >
                <Refresh />
              </IconButton>
              <IconButton
                aria-label="Fechar"
                color="inherit"
                disabled
                edge="end"
              >
                <Close />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <div
          className={clsx(chargeViewDialogClasses.container, classes.container)}
        >
          <CircularProgress />
        </div>
      </>
    );
  }

  return <ChargeViewDialog charge={charge} onClose={onClose} />;
}
