import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import useStyles from './charge-list.styles';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../services/api';
import { useAuthContext } from '../../../hooks/useAuthContext.hook';
import type { IHttpPagination } from '../../../types/services/resource-list.types';
import { useSearchParams } from '../../../hooks/use-search-params.hook';
import { Pagination } from '../../../entities/pagination';
import { PaginationFactory } from '../../../factories/pagination-factory';
import type { IHttpPopulateCharge } from '../../../types/entities/charge-entity';
import { useLoadingBar } from 'react-top-loading-bar';
import { ChargeListTableFactory } from './components/charge-list-table/charge-list-table-factory.component';
import type { TTableListState } from '../../../components/data-display/table/table.types';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import ChargeListToolbar from './components/charge-list-toolbar';
import { Refresh } from '@material-ui/icons';

export function ChargeList() {
  const { user } = useAuthContext();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const marketplaceId = user.marketplace_id;

  const history = useHistory();
  const classes = useStyles();
  const searchParams = useSearchParams();

  const { start, complete } = useLoadingBar();

  const page = params.get('page');
  const limit = params.get('limit');

  const pagination = React.useMemo(() => {
    const pageAsNumber = page ? Number(page) : null;
    const limitAsNumber = limit ? Number(limit) : null;

    return new Pagination({
      limit: limitAsNumber,
      page: pageAsNumber,
    });
  }, [limit, page]);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const hasFilters = React.useCallback(() => {
    return page !== null || limit !== null;
  }, [page, limit]);

  const {
    data: chargeList = PaginationFactory.create(),
    refetch,
    status,
    isFetching,
  } = useQuery({
    queryKey: ['charges', marketplaceId, pagination.limit, pagination.offset],
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity,
    queryFn: async () => {
      start();

      const response = await api.get<IHttpPagination<IHttpPopulateCharge>>(
        `/v2/marketplaces/${marketplaceId}/charges/by/groups`,
        {
          params: {
            limit: pagination.limit,
            offset: pagination.offset,
            populate: ['buyer'].join(','),
          },
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
        }
      );

      complete();
      return response.data;
    },
  });

  const state = React.useMemo((): TTableListState => {
    switch (status) {
      case 'success':
        if (chargeList.items.length > 0) {
          return 'list';
        }

        return chargeList.items.length === 0 && !hasFilters()
          ? 'empty'
          : 'empty-filtered';
      case 'error':
        return 'failed';
      default:
        return 'loading';
    }
  }, [status, chargeList.items.length, hasFilters]);

  function handleClearFilters() {
    searchParams.remove('page');
    searchParams.remove('limit');
  }

  function handleTooltipOpen() {
    setTooltipOpen(true);
  }

  function handleTooltipClose() {
    setTooltipOpen(false);
  }

  function handleCreateClick() {
    history.push('/charges/create');
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Tooltip
            arrow
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            open={tooltipOpen}
            title={isFetching ? 'Atualizando' : 'Atualizar'}
          >
            <IconButton onClick={() => refetch()}>
              <Refresh
                className={clsx({ [classes.rotateAnimation]: isFetching })}
              />
            </IconButton>
          </Tooltip>
        }
        subheader={
          <Typography color="textSecondary" variant="h5">
            Gerencie suas cobranças
          </Typography>
        }
        title={<Typography variant="h3">Cobranças</Typography>}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <ChargeListToolbar />
        <ChargeListTableFactory
          charges={chargeList.items}
          onClearFilters={handleClearFilters}
          onCreate={handleCreateClick}
          onRefresh={refetch}
          pagination={{
            page: chargeList.page,
            limit: chargeList.limit,
            total: chargeList.total,
          }}
          state={state}
        />
      </CardContent>
    </Card>
  );
}
