import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Cancel, PauseCircleFilled, Settings } from '@material-ui/icons';

import useStyles from './charge-view-dialog-configuration.styles';

export function ChargeViewDialogConfiguration() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={isMenuOpen}>
        <MenuItem className={classes.menuItem} disabled>
          <Cancel />
          Cancelar todas as cobranças
        </MenuItem>
        <MenuItem className={classes.menuItem} disabled>
          <PauseCircleFilled />
          Pausar todas as cobranças
        </MenuItem>
      </Menu>
      <IconButton
        aria-label="Configurações"
        color="inherit"
        edge="end"
        onClick={handleClick}
      >
        <Settings />
      </IconButton>
    </>
  );
}
