import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.dark2,
    borderRadius: 4,
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  space: {
    flex: 1,
  },
  checkbox: {
    padding: 0,
    margin: 'auto',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 7px',
  },
  itemSelected: {
    background: Mui.alpha(theme.palette.background.dark, 0.3),
  },
  tableDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableTablet: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  iconButton: {
    fontSize: 18,
  },
  buttonEdit: {
    marginRight: theme.spacing(1),
  },
  options: {
    visibility: 'hidden',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      visibility: 'visible',
    },
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.complementary.grey,
    },
  },
  icon: {
    color: theme.palette.white,
  },
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
}));

export default useStyles;
