import React from 'react';
import type { IHttpBuyer } from '../../../../../types/entities/buyer';
import * as Mui from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import {
  getDisplayEmail,
  getDisplayPhoneNumber,
  getInitials,
} from '../../../../../helpers';
import useStyles from './customer-list-table.styles';
import type { ICustomerListPaginationProps } from '../customer-list.types';
import { useSearchParams } from '../../../../../hooks/use-search-params.hook';

interface ICustomerListTableProps {
  customers: IHttpBuyer[];
  customersSelected: IHttpBuyer[];
  pagination: ICustomerListPaginationProps;
  addCustomer: (customer: IHttpBuyer) => void;
  addManyCustomers: (customers: IHttpBuyer[]) => void;
  removeCustomer: (customer: IHttpBuyer) => void;
  removeManyCustomers: (customers: IHttpBuyer[]) => void;
}

export function CustomerListTable(props: ICustomerListTableProps) {
  const {
    customers,
    customersSelected,
    pagination,
    addCustomer,
    addManyCustomers,
    removeCustomer,
    removeManyCustomers,
  } = props;

  const classes = useStyles();

  const searchParams = useSearchParams();

  const theme = useTheme();

  const customerSelectedIds = customersSelected.map(customer => customer.id);

  function getDisplaySecondaryEmail(email: string) {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values.sm) {
      return email;
    }

    return '';
  }

  function getDisplaySecondaryPhoneNumber(phoneNumber: string) {
    const width = window.innerWidth;

    if (width < theme.breakpoints.values.md) {
      return phoneNumber;
    }

    return '';
  }

  function handleCheckboxChange(checked: boolean, customer: IHttpBuyer) {
    if (checked) {
      addCustomer(customer);
    } else {
      removeCustomer(customer);
    }
  }

  function handleMainCheckboxChange(checked: boolean) {
    if (checked) {
      addManyCustomers(
        customers.filter(customer => !customerSelectedIds.includes(customer.id))
      );
    } else {
      removeManyCustomers(
        customers.filter(customer => customerSelectedIds.includes(customer.id))
      );
    }
  }

  function handlePageChange(
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) {
    searchParams.add('page', String(page + 1));
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    searchParams.add('limit', event.target.value);
    searchParams.add('page', '1');
  }

  return (
    <Mui.Grid className={classes.container} component={Mui.List} container>
      <Mui.Grid className={classes.paginationContainer} container>
        <Mui.Typography>
          {customersSelected.length} cliente(s) selecionado(s)
        </Mui.Typography>
        <div className={classes.space} />
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination.total}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={pagination.page - 1}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={[30, 50, 100]}
        />
      </Mui.Grid>

      <Mui.ListItem disableGutters>
        <Mui.Grid className={classes.tableCell} item>
          <Mui.Checkbox
            checked={
              customersSelected.length > 0 &&
              customersSelected.length === pagination.total
            }
            className={classes.checkbox}
            color="default"
            indeterminate={
              customersSelected.length > 0 &&
              customersSelected.length < pagination.total
            }
            onChange={(_, checked) => handleMainCheckboxChange(checked)}
          />
        </Mui.Grid>
        <Mui.Grid className={classes.tableCell} item md={3} sm={5} xs={7}>
          <Mui.Typography noWrap variant="h5">
            Nome
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableTablet)}
          item
          lg={4}
          md={4}
          sm={5}
        >
          <Mui.Typography noWrap variant="h5">
            E-mail
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          className={clsx(classes.tableCell, classes.tableDesktop)}
          item
          lg={3}
          md={3}
        >
          <Mui.Typography noWrap variant="h5">
            Celular
          </Mui.Typography>
        </Mui.Grid>
      </Mui.ListItem>

      <Mui.Grid component={Mui.Divider} item xs={12} />

      {customers.map((customer: IHttpBuyer) => {
        const phoneNumberFormatted = getDisplayPhoneNumber(
          customer.phoneNumber
        );
        const displayEmail = getDisplayEmail(customer.email);

        return (
          <React.Fragment key={customer.id}>
            <Mui.ListItem
              className={clsx(classes.listItem, {
                [classes.itemSelected]: customerSelectedIds.includes(
                  customer.id
                ),
              })}
              disableGutters
            >
              <Mui.Grid className={classes.tableCell} item>
                <Mui.Checkbox
                  checked={customerSelectedIds.includes(customer.id)}
                  className={classes.checkbox}
                  color="default"
                  onChange={(_, checked) =>
                    handleCheckboxChange(checked, customer)
                  }
                />
              </Mui.Grid>
              <Mui.Grid className={classes.tableCell} item md={3} sm={5} xs={7}>
                <Mui.ListItemAvatar>
                  <Mui.Avatar>{getInitials(customer.name)}</Mui.Avatar>
                </Mui.ListItemAvatar>
                <Mui.ListItemText
                  disableTypography
                  primary={
                    <Mui.Typography noWrap>{customer.name}</Mui.Typography>
                  }
                  secondary={
                    <Mui.Grid component="span" container direction="column">
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplaySecondaryEmail(displayEmail)}
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid component="span" item xs zeroMinWidth>
                        <Mui.Typography component="div" noWrap variant="body2">
                          {getDisplaySecondaryPhoneNumber(phoneNumberFormatted)}
                        </Mui.Typography>
                      </Mui.Grid>
                    </Mui.Grid>
                  }
                />
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableTablet)}
                item
                md={4}
                sm={5}
              >
                <Mui.Typography noWrap variant="body1">
                  {displayEmail}
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                className={clsx(classes.tableCell, classes.tableDesktop)}
                item
                md={3}
              >
                <Mui.Typography noWrap variant="body1">
                  {phoneNumberFormatted}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.ListItem>

            <Mui.Grid component={Mui.Divider} item xs={12} />
          </React.Fragment>
        );
      })}

      <Mui.Grid className={classes.paginationContainer} container>
        <Mui.Typography>
          {customersSelected.length} cliente(s) selecionado(s)
        </Mui.Typography>
        <div className={classes.space} />
        <Mui.TablePagination
          SelectProps={{
            classes: {
              icon: classes.icon,
            },
            MenuProps: {
              classes: {
                paper: classes.menuList,
              },
            },
          }}
          backIconButtonText="Voltar página"
          component="div"
          count={pagination?.total || 0}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to === -1 ? count : to} de ${
              count !== -1 ? count : `mais que ${to}`
            }`
          }
          labelRowsPerPage=""
          nextIconButtonText="Próxima página"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={pagination.page - 1}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={[1, 30, 50, 100]}
        />
      </Mui.Grid>
    </Mui.Grid>
  );
}
