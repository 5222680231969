import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  type: 'dark',
  black,
  white,
  primary: {
    contrastText: white,
    main: '#B43DE7',
  },
  secondary: {
    contrastText: white,
    main: '#4c2db2',
  },
  third: {
    dark: '#b15f98',
    main: '#FD89DA',
    light: '#fda0e1',
  },
  gradient: {
    main: 'linear-gradient(81.02deg, #4c2db2 -23.47%, #B43DE7 45.52%, #FD89DA 114.8%)',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: white,
    secondary: '#F4EDE8',
    third: '#131723',
    fourth: '#B43DE7',
    link: '#B43DE7',
  },
  background: {
    default: '#131723',
    dark: '#090c15',
    dark2: '#1E222F',
    paper: white,
  },
  complementary: {
    grey: '#313542',
    darkGrey: '#3F3F44',
    lightGrey: '#BBBBBB',
    lightGrey2: '#888888',
  },
  icon: colors.grey[500],
  divider: '#313542',
  action: {
    disabledBackground: '#1E222F',
    disabled: 'rgba(255, 255, 255, 0.26)',
  },
};
