import * as Mui from '@material-ui/core';
import { CopyToClipboardButton } from '../../../../components/fields/copy-to-clipboard-button/copy-to-clipboard-button.component';
import { ChargeUtils } from '../../../../helpers/charge-utils';
import React from 'react';
import type { IHttpPopulateCharge } from '../../../../types/entities/charge-entity';
import useStyles from './charge-view-dialog-info.styles';
import type { IHttpPopulateChargeMetrics } from '../../../../types/entities/charge-metrics-entity';
import { ChargeMetricsUtils } from '../../../../helpers/charge-metrics-utils';
import { Skeleton } from '@material-ui/lab';

interface IChargeViewDialogInfoProps {
  charge: IHttpPopulateCharge;
  chargeMetrics: IHttpPopulateChargeMetrics | null;
  isLoadingChargeMetrics: boolean;
}

export function ChargeViewDialogInfo(props: IChargeViewDialogInfoProps) {
  const { charge, chargeMetrics, isLoadingChargeMetrics } = props;

  const classes = useStyles();

  const displayAmount = React.useMemo(
    () => ChargeMetricsUtils.formatTotalAmount(chargeMetrics?.totalAmount || 0),
    [chargeMetrics]
  );

  const displayPaymentTypesAccepted = React.useMemo(
    () =>
      ChargeUtils.toPaymentTypesAcceptedLabel(charge.paymentTypesAccepted).join(
        ', '
      ),
    [charge.paymentTypesAccepted]
  );

  return (
    <div>
      <Mui.AppBar
        className={classes.cardAppBar}
        color="secondary"
        position="static"
      >
        <Mui.Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <Mui.Typography variant="h5">
              Informações da cobrança
            </Mui.Typography>
          </div>
        </Mui.Toolbar>
      </Mui.AppBar>
      <div className={classes.cardContainer}>
        <Mui.Grid container spacing={2}>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              ID da primeira cobrança
            </Mui.Typography>
            <CopyToClipboardButton textToCopy={charge.id}>
              {ChargeUtils.shortUuidV4(charge.id)}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Status da cobrança</Mui.Typography>
            {!isLoadingChargeMetrics ? (
              <Mui.Typography>
                {ChargeMetricsUtils.toStatusLabel(chargeMetrics!.status)}
              </Mui.Typography>
            ) : (
              <Skeleton width="30%" />
            )}
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Valor total da cobrança
            </Mui.Typography>
            {!isLoadingChargeMetrics ? (
              <CopyToClipboardButton textToCopy={displayAmount}>
                {displayAmount}
              </CopyToClipboardButton>
            ) : (
              <Skeleton width="20%" />
            )}
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              ID do grupo da cobrança
            </Mui.Typography>
            <CopyToClipboardButton textToCopy={charge.groupChargeId}>
              {ChargeUtils.shortUuidV4(charge.groupChargeId)}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Descrição</Mui.Typography>
            <Mui.Typography>{charge.description}</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Métodos de pagamento</Mui.Typography>
            <CopyToClipboardButton textToCopy={displayPaymentTypesAccepted}>
              {displayPaymentTypesAccepted}
            </CopyToClipboardButton>
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Moeda da cobrança</Mui.Typography>
            <CopyToClipboardButton textToCopy={charge.currency}>
              {charge.currency}
            </CopyToClipboardButton>
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Qtd. de parcelas</Mui.Typography>
            {!isLoadingChargeMetrics ? (
              <Mui.Typography>
                {chargeMetrics!.totalCharges} parcela(s)
              </Mui.Typography>
            ) : (
              <Skeleton width="25%" />
            )}
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Data da primeira cobrança
            </Mui.Typography>
            <CopyToClipboardButton
              textToCopy={
                ChargeUtils.formatPaymentLimitDate(charge.paymentLimitDate) ||
                ''
              }
            >
              {ChargeUtils.formatPaymentLimitDate(charge.paymentLimitDate)}
            </CopyToClipboardButton>
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Data da próxima cobrança
            </Mui.Typography>
            {!isLoadingChargeMetrics ? (
              <CopyToClipboardButton
                textToCopy={
                  ChargeMetricsUtils.formatNextDueDate(
                    chargeMetrics!.nextDueDate
                  ) || ''
                }
              >
                {ChargeMetricsUtils.formatNextDueDate(
                  chargeMetrics!.nextDueDate
                ) || 'Sem data'}
              </CopyToClipboardButton>
            ) : (
              <Skeleton width="35%" />
            )}
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Data do último pagamento
            </Mui.Typography>
            {!isLoadingChargeMetrics ? (
              <CopyToClipboardButton
                textToCopy={
                  ChargeMetricsUtils.formatLastPaymentDate(
                    chargeMetrics!.lastPaymentDate
                  ) || ''
                }
              >
                {ChargeMetricsUtils.formatLastPaymentDate(
                  chargeMetrics!.lastPaymentDate
                ) || 'Sem data'}
              </CopyToClipboardButton>
            ) : (
              <Skeleton width="30%" />
            )}
          </Mui.Grid>
          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Data da criação da primeira cobrança
            </Mui.Typography>
            <CopyToClipboardButton
              textToCopy={ChargeUtils.formatCreatedAt(charge.createdAt)}
            >
              {ChargeUtils.formatCreatedAt(charge.createdAt)}
            </CopyToClipboardButton>
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">Dias de atraso</Mui.Typography>
            {!isLoadingChargeMetrics ? (
              <Mui.Typography>{chargeMetrics!.daysOverdue}</Mui.Typography>
            ) : (
              <Skeleton width="35%" />
            )}
          </Mui.Grid>

          <Mui.Grid item md={4} sm={6} xs={12}>
            <Mui.Typography variant="h6">
              Último método de pagamento utilizado
            </Mui.Typography>
            {!isLoadingChargeMetrics ? (
              <Mui.Typography>
                {ChargeMetricsUtils.formatLastPaymentMethodUsed(
                  chargeMetrics!.lastPaymentMethodUsed
                ) || 'Não encontrado'}
              </Mui.Typography>
            ) : (
              <Skeleton width="20%" />
            )}
          </Mui.Grid>
        </Mui.Grid>
      </div>
    </div>
  );
}
