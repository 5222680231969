import React from 'react';
import * as MuiPickers from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import * as Mui from '@material-ui/core';
import { SearchInput } from '../../../../components';
import useStyles from './customer-list-toolbar.styles';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useSearchParams } from '../../../../hooks/use-search-params.hook';
import { BrowserEvent } from '../../../../services/browser-event';
import { useLocation } from 'react-router-dom';

interface ICustomerListToolbarProps {
  onClearFilters: () => void;
}

export function CustomerListToolbar(props: ICustomerListToolbarProps) {
  const { onClearFilters } = props;

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const searchParams = useSearchParams();
  const classes = useStyles();

  const searchInitialState = params.get('search') || '';
  const dateStartInitialState = params.get('dateStart') || null;
  const dateEndInitialState = params.get('dateEnd') || null;

  const [search, setSearch] = React.useState<string>(searchInitialState);
  const [dateStart, setDateStart] = React.useState<string | null>(
    dateStartInitialState
  );
  const [dateEnd, setDateEnd] = React.useState<string | null>(
    dateEndInitialState
  );

  function handleDateStartChange(momentDate: MaterialUiPickersDate) {
    const dateStart = momentDate?.toISOString() || null;

    setDateStart(dateStart);
  }

  function handleDateEndChange(momentDate: MaterialUiPickersDate) {
    const dateEnd = momentDate?.toISOString() || null;

    setDateEnd(dateEnd);
  }

  function handleDisableDateEnd(momentDate: MaterialUiPickersDate) {
    return !!momentDate?.isBefore(dateStartInitialState);
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function handleFilterClick() {
    searchParams.add('search', search);
    searchParams.add('dateStart', dateStart || '');
    searchParams.add('dateEnd', dateEnd || '');
  }

  const clearFilters = React.useCallback(() => {
    setSearch('');
    setDateStart(null);
    setDateEnd(null);
  }, []);

  const handleClearFilters = React.useCallback(() => {
    clearFilters();
    onClearFilters();
  }, [clearFilters, onClearFilters]);

  React.useEffect(() => {
    BrowserEvent.addListener(
      '@fpay-backoffice:charge.clear-filters',
      clearFilters
    );

    return () => {
      BrowserEvent.removeListener(
        '@fpay-backoffice:charge.clear-filters',
        clearFilters
      );
    };
  }, [clearFilters]);

  return (
    <form className={classes.root} onSubmit={event => event.preventDefault()}>
      <div className={classes.row}>
        <div className={classes.leftActionsContainer}>
          <MuiPickers.MuiPickersUtilsProvider
            libInstance={moment}
            locale="pt-br"
            utils={MomentUtils}
          >
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              className={classes.dateStartPicker}
              clearLabel="LIMPAR"
              clearable
              disableFuture
              format="DD/MM/YYYY"
              id="dateStart"
              inputVariant="outlined"
              label="De"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              margin="dense"
              name="dateStart"
              onChange={handleDateStartChange}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              value={dateStart}
            />
            <MuiPickers.DatePicker
              DialogProps={{
                PaperProps: {
                  classes: {
                    root: classes.rootDialog,
                  },
                },
              }}
              cancelLabel="CANCELAR"
              className={classes.dateEndPicker}
              clearLabel="LIMPAR"
              clearable
              disableFuture
              format="DD/MM/YYYY"
              id="dateEnd"
              inputVariant="outlined"
              label="Até"
              leftArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              margin="dense"
              name="dateEnd"
              onChange={handleDateEndChange}
              rightArrowButtonProps={{
                classes: {
                  root: classes.buttonPicker,
                },
              }}
              shouldDisableDate={handleDisableDateEnd}
              value={dateEnd}
            />
          </MuiPickers.MuiPickersUtilsProvider>
          <Mui.Button
            color="primary"
            onClick={handleClearFilters}
            variant="outlined"
          >
            Limpar Filtro
          </Mui.Button>
          <Mui.Button
            color="primary"
            onClick={handleFilterClick}
            type="submit"
            variant="contained"
          >
            Filtrar
          </Mui.Button>
        </div>
        <span className={classes.spacer} />
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          onChange={handleSearchChange}
          placeholder="Pesquise um cliente"
          type="search"
          value={search}
        />
      </div>
    </form>
  );
}
