import clsx from 'clsx';
import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { CustomerListToolbar } from './customer-list-toolbar.component';
import type { ICustomerListTableFactoryProps } from './customer-list-table-factory.component';
import { CustomerListTableFactory } from './customer-list-table-factory.component';
import type { IHttpBuyer } from '../../../../types/entities/buyer';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../services/api';
import { useAuthContext } from '../../../../hooks/useAuthContext.hook';
import type { IHttpPagination } from '../../../../types/services/resource-list.types';
import { PaginationFactory } from '../../../../factories/pagination-factory';
import moment from 'moment';
import { useLoadingBar } from 'react-top-loading-bar';
import { useSearchParams } from '../../../../hooks/use-search-params.hook';
import { BrowserEvent } from '../../../../services/browser-event';
import { useBuyerUI } from '../../../../hooks/useBuyer';
import { useLocation } from 'react-router-dom';

const useStyles = Mui.makeStyles(theme => ({
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  rotateAnimation: {
    animation: '$rotate 1.2s linear infinite',
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

interface ICustomerListProps {
  customersSelected: IHttpBuyer[];
  addCustomer: (customer: IHttpBuyer) => void;
  addManyCustomers: (customers: IHttpBuyer[]) => void;
  removeCustomer: (customer: IHttpBuyer) => void;
  removeManyCustomers: (customers: IHttpBuyer[]) => void;
}

export function CustomerList(props: ICustomerListProps) {
  const {
    customersSelected,
    addCustomer,
    addManyCustomers,
    removeCustomer,
    removeManyCustomers,
  } = props;

  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const searchParams = useSearchParams();

  const search = params.get('search');
  const dateStart = params.get('dateStart');
  const dateEnd = params.get('dateEnd');
  const page = params.get('page');
  const limit = params.get('limit');

  const { user } = useAuthContext();
  const { marketplace_id: marketplaceId } = user;

  const { start, complete } = useLoadingBar();

  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const buyerUI = useBuyerUI();

  const hasFilters = React.useCallback((): boolean => {
    return (
      search !== '' ||
      dateStart !== null ||
      dateEnd !== null ||
      page !== null ||
      limit !== null
    );
  }, [dateEnd, dateStart, limit, page, search]);

  const {
    data: customersList = PaginationFactory.create(),
    status,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [
      'buyers',
      search,
      dateStart,
      dateEnd,
      marketplaceId,
      limit,
      page,
    ],
    staleTime: 1000 * 60 * 5,
    cacheTime: Infinity,
    queryFn: async () => {
      start();

      let offset: number | undefined;
      const pageAsNumber = Number(page);
      const limitAsNumber = Number(limit);
      const defaultLimit = 30;
      const maxLimit = 100;

      if (pageAsNumber && pageAsNumber > 0) {
        if (pageAsNumber === 1) {
          offset = 0;
        }

        if (pageAsNumber > 1) {
          offset = (pageAsNumber - 1) * (limitAsNumber || defaultLimit);
        }
      }

      const response = await api.get<IHttpPagination<IHttpBuyer>>(
        `/v2/marketplaces/${marketplaceId}/buyers`,
        {
          params: {
            createdAtStart: dateStart
              ? moment.utc(dateStart).startOf('day').toISOString()
              : undefined,
            createdAtEnd: dateEnd
              ? moment.utc(dateEnd).endOf('day').toISOString()
              : undefined,
            search: search ? search : undefined,
            limit: limitAsNumber > maxLimit ? maxLimit : limit || defaultLimit,
            offset,
          },
          auth: {
            username: process.env.REACT_APP_BYPASS_API_KEY!,
            password: '',
          },
        }
      );

      complete();
      return response.data;
    },
  });

  const state = React.useMemo((): ICustomerListTableFactoryProps['state'] => {
    switch (status) {
      case 'success':
        if (customersList.items.length > 0) {
          return 'list';
        }

        return customersList.items.length === 0 && !hasFilters()
          ? 'empty'
          : 'empty-filtered';
      case 'error':
        return 'failed';
      default:
        return 'loading';
    }
  }, [status, customersList.items.length, hasFilters]);

  function handleTooltipOpen() {
    setTooltipOpen(true);
  }

  function handleTooltipClose() {
    setTooltipOpen(false);
  }

  function handleClearFilters() {
    searchParams.remove('dateEnd');
    searchParams.remove('dateStart');
    searchParams.remove('limit');
    searchParams.remove('page');
    searchParams.remove('search');

    const clearFilterEvent = BrowserEvent.create(
      '@fpay-backoffice:charge.clear-filters'
    );

    BrowserEvent.dispatch(clearFilterEvent);
  }

  React.useEffect(() => {
    setTooltipOpen(isFetching);
  }, [isFetching]);

  return (
    <React.Fragment>
      <Mui.CardHeader
        action={
          <Mui.Tooltip
            arrow
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            open={tooltipOpen}
            title={isFetching ? 'Atualizando' : 'Atualizar'}
          >
            <Mui.IconButton disabled={isFetching} onClick={() => refetch()}>
              <MuiIcons.Refresh
                className={clsx({ [classes.rotateAnimation]: isFetching })}
              />
            </Mui.IconButton>
          </Mui.Tooltip>
        }
        subheader={
          <Mui.Typography color="textSecondary" variant="h5">
            Selecione o(s) cliente(s) que deseja cobrar
          </Mui.Typography>
        }
        title={<Mui.Typography variant="h3">Clientes</Mui.Typography>}
      />
      <Mui.Divider />
      <Mui.CardContent className={classes.cardContent}>
        <CustomerListToolbar onClearFilters={handleClearFilters} />
        <CustomerListTableFactory
          addCustomer={addCustomer}
          addManyCustomers={addManyCustomers}
          customers={customersList.items}
          customersSelected={customersSelected}
          onClearFilters={handleClearFilters}
          onRefresh={refetch}
          openCreateCustomerModal={buyerUI.openToCreate}
          pagination={{
            page: customersList.page,
            limit: customersList.limit,
            total: customersList.total,
          }}
          removeCustomer={removeCustomer}
          removeManyCustomers={removeManyCustomers}
          state={state}
        />
      </Mui.CardContent>
    </React.Fragment>
  );
}
