import * as Mui from '@material-ui/core';
import {
  Cancel,
  Edit,
  MoreVert,
  PauseCircleFilled,
  Send,
  Share,
  WhatsApp,
} from '@material-ui/icons';
import React from 'react';
import type { IHttpPopulateCharge } from '../../../../../types/entities/charge-entity';
import type { IChargeViewDialogTableColumn } from './charge-view-dialog-table.component';
import { useLoadingBar } from 'react-top-loading-bar';
import { useSnackbar } from '../../../../../components/feedback/snackbar/use-snackbar.hook';
import useStyles from './charge-view-dialog-table-row.styles';
import { api } from '../../../../../services/api';

interface IChargeViewDialogTableRowProps {
  charge: IHttpPopulateCharge;
  columns: IChargeViewDialogTableColumn<IHttpPopulateCharge>[];
}

export function ChargeViewDialogTableRow(
  props: IChargeViewDialogTableRowProps
) {
  const { charge, columns } = props;

  const classes = useStyles();

  const { start, complete } = useLoadingBar();
  const { enqueueSnackbar } = useSnackbar();

  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const menuIsOpen = Boolean(moreAnchorEl);

  async function sendEmail(charge: IHttpPopulateCharge) {
    start();

    await api.post(
      '/integrations/upstash-webhooks/charges/send-to-customer',
      {
        id: charge.id,
        marketplaceId: charge.marketplaceId,
        sellerId: charge.sellerId,
        buyerId: charge.buyerId,
      },
      {
        headers: {
          'Recebafacil-Signature': process.env.REACT_APP_BYPASS_API_KEY,
        },
        params: {
          notification: 'in-memory',
        },
      }
    );

    complete();

    enqueueSnackbar('E-mail enviado com sucesso', { variant: 'success' });
  }

  async function generatePaymentLink() {
    const checkoutUrl = `${process.env.REACT_APP_BASE_URL_CHECKOUT}/charges/${charge.id}`;

    await navigator.clipboard.writeText(checkoutUrl);

    enqueueSnackbar('Link de pagamento copiado para a área de transferência', {
      variant: 'success',
    });
  }

  async function handleSendEmailClick(charge: IHttpPopulateCharge) {
    handleClose();
    await sendEmail(charge);
  }

  async function handleGeneratePaymentLinkClick() {
    handleClose();
    await generatePaymentLink();
  }

  function handleClose() {
    setMoreAnchorEl(null);
  }

  return (
    <Mui.TableRow hover key={charge.id} role="checkbox" tabIndex={-1}>
      <Mui.TableCell padding="checkbox">
        <Mui.IconButton onClick={e => setMoreAnchorEl(e.currentTarget)}>
          <MoreVert />
        </Mui.IconButton>
        <Mui.Menu
          anchorEl={moreAnchorEl}
          onClose={handleClose}
          open={menuIsOpen}
        >
          <Mui.MenuItem
            className={classes.menuItem}
            onClick={handleGeneratePaymentLinkClick}
          >
            <Share fontSize="small" />
            Gerar link de pagamento
          </Mui.MenuItem>
          <Mui.MenuItem
            className={classes.menuItem}
            onClick={() => handleSendEmailClick(charge)}
          >
            <Send fontSize="small" />
            {charge.emailSendAt ? 'Reenviar por e-mail' : 'Enviar por e-mail'}
          </Mui.MenuItem>
          <Mui.MenuItem className={classes.menuItem} disabled>
            <WhatsApp fontSize="small" />
            <Mui.Typography variant="inherit">
              Enviar por WhatsApp
            </Mui.Typography>
          </Mui.MenuItem>
          <Mui.MenuItem className={classes.menuItem} disabled>
            <Edit fontSize="small" />
            Editar cobrança
          </Mui.MenuItem>
          <Mui.MenuItem className={classes.menuItem} disabled>
            <Cancel fontSize="small" />
            Cancelar cobrança
          </Mui.MenuItem>
          <Mui.MenuItem className={classes.menuItem} disabled>
            <PauseCircleFilled fontSize="small" />
            Pausar cobrança
          </Mui.MenuItem>
        </Mui.Menu>
      </Mui.TableCell>
      {columns.map(column => {
        const Component = column.cellComponent;

        return (
          <Mui.TableCell align={column.align} key={column.id}>
            <Component value={charge} />
          </Mui.TableCell>
        );
      })}
    </Mui.TableRow>
  );
}
