import React from 'react';
import { TableEmptyStateInitial } from '../../../../components/data-display/table/implementations/table-empty-state-initial.component';
import { TableLoading } from '../../../../components/data-display/table/implementations/table-loading.component';
import { TableFailedState } from '../../../../components/data-display/table/implementations/table-failed-state.component';
import { TableEmptyStateFiltered } from '../../../../components/data-display/table/implementations/table-empty-state-filtered.component';
import type { IHttpBuyer } from '../../../../types/entities/buyer';
import { CustomerListTable } from './customer-table/customer-list-table.component';
import type { ICustomerListPaginationProps } from './customer-list.types';
import type { TTableListState } from '../../../../components/data-display/table/table.types';

export interface ICustomerListTableFactoryProps {
  customers: IHttpBuyer[];
  customersSelected: IHttpBuyer[];
  pagination: ICustomerListPaginationProps;
  state: TTableListState;
  addCustomer: (customer: IHttpBuyer) => void;
  addManyCustomers: (customers: IHttpBuyer[]) => void;
  openCreateCustomerModal: () => void;
  onClearFilters: () => void;
  onRefresh: () => void;
  removeCustomer: (customer: IHttpBuyer) => void;
  removeManyCustomers: (customers: IHttpBuyer[]) => void;
}

export function CustomerListTableFactory(
  props: ICustomerListTableFactoryProps
) {
  const {
    customers,
    customersSelected,
    pagination,
    state,
    addCustomer,
    addManyCustomers,
    openCreateCustomerModal,
    onClearFilters,
    onRefresh,
    removeCustomer,
    removeManyCustomers,
  } = props;

  switch (state) {
    case 'loading':
      return <TableLoading />;
    case 'empty':
      return (
        <TableEmptyStateInitial
          buttonLabel="Crie um agora"
          label="Você ainda não possui cliente!"
          onCreate={openCreateCustomerModal}
        />
      );
    case 'failed':
      return <TableFailedState onRefresh={onRefresh} />;
    case 'empty-filtered':
      return <TableEmptyStateFiltered onClearFilters={onClearFilters} />;
    case 'list':
      return (
        <CustomerListTable
          addCustomer={addCustomer}
          addManyCustomers={addManyCustomers}
          customers={customers}
          customersSelected={customersSelected}
          pagination={pagination}
          removeCustomer={removeCustomer}
          removeManyCustomers={removeManyCustomers}
        />
      );
    default:
      return null;
  }
}
