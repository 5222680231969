import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiLab from '@material-ui/lab';

import * as MuiIcons from '@material-ui/icons';
import useStyles from './overview-card.styles';

interface IOverviewCardProps {
  failed: boolean;
  loaded: boolean;
  text: React.ReactNode | React.ReactNode[];
  title: React.ReactNode | React.ReactNode[];
  onRefresh(event: React.MouseEvent<HTMLButtonElement>): void;
}

export function OverviewCard(props: IOverviewCardProps) {
  const { failed, loaded, text, title, onRefresh } = props;

  const classes = useStyles();

  return (
    <Mui.Card className={classes.root}>
      {!loaded && (
        <>
          <MuiLab.Skeleton
            animation="pulse"
            height={32}
            variant="text"
            width={120}
          />
          <MuiLab.Skeleton
            animation="pulse"
            height={32}
            variant="text"
            width={70}
          />
        </>
      )}
      {loaded && !failed && (
        <>
          <Mui.Typography className={classes.title} variant="h5">
            {title}
          </Mui.Typography>
          <Mui.Typography className={classes.text} variant="h4">
            {text}
          </Mui.Typography>
        </>
      )}
      {loaded && failed && <OverviewCardFailed onClick={onRefresh} />}
    </Mui.Card>
  );
}

interface IOverviewCardFailedProps {
  onClick(event: React.MouseEvent<HTMLButtonElement>): void;
}

function OverviewCardFailed(props: IOverviewCardFailedProps) {
  const { onClick } = props;

  return (
    <>
      <Mui.Typography align="center" variant="body2">
        Não foi possível se conectar ao servidor. Tente novamente...
      </Mui.Typography>
      <Mui.IconButton onClick={onClick}>
        <MuiIcons.Refresh />
      </Mui.IconButton>
    </>
  );
}
