import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  paper: {
    borderRadius: theme.spacing(1),
    maxWidth: '100%',
    backgroundColor: theme.palette.background.dark2,
    overflow: 'hidden',
  },
  table: {
    minWidth: 'max-content',
  },
  tableTh: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default useStyles;
