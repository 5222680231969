import type { IHttpPagination } from '../types/services/resource-list.types';

export class PaginationFactory {
  public static create<T>(): IHttpPagination<T> {
    return {
      resource: 'list',
      items: [],
      limit: 0,
      offset: 0,
      page: 0,
      total: 0,
      totalPages: 0,
      hasMore: false,
    };
  }
}
