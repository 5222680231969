import React from 'react';

import type { IHttpPopulateCharge } from '../../../../types/entities/charge-entity';
import { ChargeUtils } from '../../../../helpers/charge-utils';
import { CopyToClipboardButton } from '../../../../components/fields/copy-to-clipboard-button/copy-to-clipboard-button.component';

interface IChargeDialogCellProps {
  value: IHttpPopulateCharge;
}

function ChargeViewDialogCellId(props: IChargeDialogCellProps) {
  const { value } = props;

  return (
    <CopyToClipboardButton textToCopy={value.id}>
      {ChargeUtils.shortUuidV4(value.id)}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellGroupId(props: IChargeDialogCellProps) {
  const { value } = props;

  return (
    <CopyToClipboardButton textToCopy={value.groupChargeId}>
      {ChargeUtils.shortUuidV4(value.groupChargeId)}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellDescription(props: IChargeDialogCellProps) {
  const { value } = props;

  return (
    <CopyToClipboardButton textToCopy={value.description}>
      {ChargeUtils.shortDescription(value.description)}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellPaymentTypesAccepted(
  props: IChargeDialogCellProps
) {
  const { value } = props;

  const paymentTypesAccepted = ChargeUtils.toPaymentTypesAcceptedLabel(
    value.paymentTypesAccepted
  ).join(', ');

  return (
    <CopyToClipboardButton textToCopy={paymentTypesAccepted}>
      {paymentTypesAccepted}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellInstallment(props: IChargeDialogCellProps) {
  const { value } = props;

  return <div>{value.installment}</div>;
}

function ChargeViewDialogCellPaymentLimitDate(props: IChargeDialogCellProps) {
  const { value } = props;

  const paymentLimitDate = value.paymentLimitDate
    ? ChargeUtils.formatPaymentLimitDate(value.paymentLimitDate)
    : 'Sem data de vencimento';

  return (
    <CopyToClipboardButton textToCopy={paymentLimitDate}>
      {paymentLimitDate}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellAmount(props: IChargeDialogCellProps) {
  const { value } = props;

  const displayAmount = React.useMemo(
    () => ChargeUtils.formatAmount(value.amount),
    [value.amount]
  );

  return (
    <CopyToClipboardButton textToCopy={displayAmount}>
      {displayAmount}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellStatus(props: IChargeDialogCellProps) {
  const { value } = props;

  const status = ChargeUtils.toStatusLabel(value.status);

  return (
    <CopyToClipboardButton textToCopy={status}>{status}</CopyToClipboardButton>
  );
}

function ChargeViewDialogCellPaidAt(props: IChargeDialogCellProps) {
  const { value } = props;

  const paidAt = ChargeUtils.formatPaidAt(value.paidAt) || 'Sem pagamento';

  return (
    <CopyToClipboardButton textToCopy={paidAt}>{paidAt}</CopyToClipboardButton>
  );
}

function ChargeViewDialogCellRefundedAt(props: IChargeDialogCellProps) {
  const { value } = props;

  const refundedAt =
    ChargeUtils.formatRefundedAt(value.refundedAt) || 'Sem reembolso';

  return (
    <CopyToClipboardButton textToCopy={refundedAt}>
      {refundedAt}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellEmailEnabled(props: IChargeDialogCellProps) {
  const { value } = props;

  const emailEnabled = value.emailEnabled ? 'Sim' : 'Não';

  return <div>{emailEnabled}</div>;
}

function ChargeViewDialogCellEmailStatus(props: IChargeDialogCellProps) {
  const { value } = props;

  const emailStatus = ChargeUtils.toEmailStatusLabel(value.emailStatus);

  return (
    <CopyToClipboardButton textToCopy={emailStatus}>
      {emailStatus}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellEmailSendAt(props: IChargeDialogCellProps) {
  const { value } = props;

  const emailSendAt =
    ChargeUtils.formatEmailSendAt(value.emailSendAt) || 'Não enviado';

  return (
    <CopyToClipboardButton textToCopy={emailSendAt}>
      {emailSendAt}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellEmailViewedAt(props: IChargeDialogCellProps) {
  const { value } = props;

  const emailViewedAt =
    ChargeUtils.formatEmailSendAt(value.emailViewedAt) || 'Não visualizado';

  return (
    <CopyToClipboardButton textToCopy={emailViewedAt}>
      {emailViewedAt}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellCreatedAt(props: IChargeDialogCellProps) {
  const { value } = props;

  const createdAt = ChargeUtils.formatCreatedAt(value.createdAt);

  return (
    <CopyToClipboardButton textToCopy={createdAt}>
      {createdAt}
    </CopyToClipboardButton>
  );
}

function ChargeViewDialogCellUpdatedAt(props: IChargeDialogCellProps) {
  const { value } = props;

  const updatedAt = ChargeUtils.formatUpdatedAt(value.updatedAt);

  return (
    <CopyToClipboardButton textToCopy={updatedAt}>
      {updatedAt}
    </CopyToClipboardButton>
  );
}

export const ChargeViewDialogCell = {
  Id: ChargeViewDialogCellId,
  GroupId: ChargeViewDialogCellGroupId,
  Description: ChargeViewDialogCellDescription,
  PaymentTypesAccepted: ChargeViewDialogCellPaymentTypesAccepted,
  Installment: ChargeViewDialogCellInstallment,
  PaymentLimitDate: ChargeViewDialogCellPaymentLimitDate,
  Amount: ChargeViewDialogCellAmount,
  Status: ChargeViewDialogCellStatus,
  PaidAt: ChargeViewDialogCellPaidAt,
  RefundedAt: ChargeViewDialogCellRefundedAt,
  EmailEnabled: ChargeViewDialogCellEmailEnabled,
  EmailStatus: ChargeViewDialogCellEmailStatus,
  EmailSendAt: ChargeViewDialogCellEmailSendAt,
  EmailViewedAt: ChargeViewDialogCellEmailViewedAt,
  CreatedAt: ChargeViewDialogCellCreatedAt,
  UpdatedAt: ChargeViewDialogCellUpdatedAt,
};
