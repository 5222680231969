import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import React from 'react';
import { ChargeUtils } from 'helpers/charge-utils';
import useStyles from './charge-view-dialog.styles';
import type { IHttpPopulateCharge } from '../../../types/entities/charge-entity';
import { useAuthContext } from '../../../hooks/useAuthContext.hook';
import { ChargeViewDialogInfo } from './components/charge-view-dialog-info.component';
import { ChargeViewDialogNotificationInfo } from './components/charge-view-dialog-notification-info.component';
import { ChargeViewDialogBuyerInfo } from './components/charge-view-dialog-buyer-info.component';
import { ChargeViewDialogTable } from './components/charge-view-dialog-table/charge-view-dialog-table.component';
import { useChargeViewDialog } from './charge-view-dialog.hook';
import { ChargeViewDialogAnalytics } from './components/charge-view-dialog-analytics.component';
import { ChargeViewDialogConfiguration } from './components/charge-view-dialog-configuration.component';

interface IChargeViewDialogProps {
  charge: IHttpPopulateCharge;
  onClose?: () => void;
}

export function ChargeViewDialog(props: IChargeViewDialogProps) {
  const { charge, onClose } = props;

  const { user } = useAuthContext();

  const marketplaceId = user.marketplace_id;

  const classes = useStyles();

  const {
    chargeList,
    chargeMetrics,
    isLoadingChargeList,
    isLoadingChargeMetrics,
    handleDialogClose,
    refetch,
  } = useChargeViewDialog({
    marketplaceId,
    charge,
    onClose,
  });

  return (
    <React.Fragment>
      <Mui.AppBar className={classes.appBar} color="default">
        <Mui.Toolbar>
          <Mui.Typography className={classes.title} variant="h5">
            {`Detalhes da cobrança (${ChargeUtils.shortUuidV4(charge.id)})`}
          </Mui.Typography>
          <div className={classes.appBarActionsContainer}>
            <ChargeViewDialogConfiguration />
            <Mui.IconButton
              aria-label="Atualizar"
              color="inherit"
              edge="end"
              onClick={refetch}
            >
              <MuiIcons.Refresh />
            </Mui.IconButton>
            <Mui.IconButton
              aria-label="Fechar"
              color="inherit"
              edge="end"
              onClick={handleDialogClose}
            >
              <MuiIcons.Close />
            </Mui.IconButton>
          </div>
        </Mui.Toolbar>
      </Mui.AppBar>
      <div className={classes.container}>
        <ChargeViewDialogAnalytics
          chargeMetrics={chargeMetrics}
          isLoadingChargeMetrics={isLoadingChargeMetrics}
        />

        <ChargeViewDialogInfo
          charge={charge}
          chargeMetrics={chargeMetrics}
          isLoadingChargeMetrics={isLoadingChargeMetrics}
        />

        <ChargeViewDialogNotificationInfo charge={charge} />

        <ChargeViewDialogBuyerInfo buyer={charge.buyer!} />

        <ChargeViewDialogTable
          chargeList={chargeList}
          isLoadingChargeList={isLoadingChargeList}
        />
      </div>
    </React.Fragment>
  );
}
