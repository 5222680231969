import React from 'react';
import { Snackbar, Typography } from '@material-ui/core';
import { SnackbarContext } from './snackbar.context';
import { Alert } from '@material-ui/lab';

interface ISnackbarAnchor {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
}

export interface ISnackbarEnqueueOptionsProps {
  anchor?: ISnackbarAnchor;
  autoHideDuration?: number;
  action?: React.ReactNode;
  variant?: 'success' | 'error' | 'warning' | 'info';
}

interface ISnackbarProps {
  children?: React.ReactNode;
}

const DEFAULT_AUTO_HIDE_DURATION = 6000;

export function SnackbarProvider(props: ISnackbarProps) {
  const { children } = props;

  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [autoHideDuration, setAutoHideDuration] = React.useState<number>(
    DEFAULT_AUTO_HIDE_DURATION
  );
  const [action, setAction] = React.useState<React.ReactNode>(null);
  const [variant, setVariant] = React.useState<
    'success' | 'error' | 'warning' | 'info'
  >('success');
  const [anchor, setAnchor] = React.useState<ISnackbarAnchor>({
    vertical: 'bottom',
    horizontal: 'left',
  });

  function handleClose() {
    setOpen(false);
  }

  const enqueueSnackbar = React.useCallback(
    (message: string, options?: ISnackbarEnqueueOptionsProps) => {
      setMessage(message);
      setOpen(true);

      if (options?.anchor) {
        setAnchor(options.anchor);
      } else {
        setAnchor({
          vertical: 'bottom',
          horizontal: 'left',
        });
      }

      if (options?.autoHideDuration) {
        setAutoHideDuration(options.autoHideDuration);
      } else {
        setAutoHideDuration(DEFAULT_AUTO_HIDE_DURATION);
      }

      if (options?.action) {
        setAction(options.action);
      }
      {
        setAction(null);
      }

      if (options?.variant) {
        setVariant(options.variant);
      } else {
        setVariant('success');
      }
    },
    []
  );

  return (
    <SnackbarContext.Provider value={{ enqueueSnackbar }}>
      <Snackbar
        action={action}
        anchorOrigin={anchor}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        open={open}
      >
        <Alert onClose={handleClose} severity={variant} variant="filled">
          <Typography variant="h6">{message}</Typography>
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}
