import React from 'react';
import * as Mui from '@material-ui/core';
import toCurrency from './currency-conversion';

export function CurrencyField(props: Mui.TextFieldProps) {
  const { onChange, value, ...rest } = props;

  const valueAsCurrency = toCurrency(value || '');

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const money = event.target.value;
    const centsStartingPosition = money.length - 2;

    const digits = money.replace(/(-(?!\d))|[^0-9|-]/g, '');
    const cents = digits.substring(centsStartingPosition);
    const moneyWithoutCents = digits.substring(0, centsStartingPosition);

    event.target.value = `${moneyWithoutCents}${cents}`;
    onChange && onChange(event);
  }

  return (
    <Mui.TextField
      onChange={handleInputChange}
      value={valueAsCurrency}
      {...rest}
    />
  );
}
