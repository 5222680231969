import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.dark2,
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {},
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
  },
  text: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
