import React from 'react';
import * as Mui from '@material-ui/core';
import clsx from 'clsx';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  appBarRounded: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  toolbar: {
    padding: theme.spacing(2),
  },
  toolbarHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarTitle: {
    marginBottom: theme.spacing(0.5),
  },
  container: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  switchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  spacer: {
    flex: 1,
  },
}));

export function ChargeDiscountConfig() {
  const classes = useStyles();

  const enabled = false;

  return (
    <>
      <Mui.AppBar
        className={clsx(classes.appBar, {
          [classes.appBarRounded]: !enabled,
        })}
        color="secondary"
        position="static"
      >
        <Mui.Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <Mui.Typography className={classes.toolbarTitle} variant="h5">
              Configurações de desconto
            </Mui.Typography>
            <Mui.Typography color="textSecondary" variant="caption">
              Escolha se você quer dar desconto antes da data de vencimento
            </Mui.Typography>
          </div>
          <div className={classes.spacer} />
          <Mui.Tooltip arrow title="Em breve">
            <div>
              <Mui.Switch disabled />
            </div>
          </Mui.Tooltip>
        </Mui.Toolbar>
      </Mui.AppBar>
      {enabled && (
        <Mui.Grid className={classes.container} container>
          <Mui.Grid item md={6} xs={12}>
            <Mui.Tooltip arrow title="Em breve">
              <div className={classes.switchFieldContainer}>
                <Mui.Switch disabled />
                <Mui.Typography variant="body1">
                  Enviar por WhatsApp
                </Mui.Typography>
              </div>
            </Mui.Tooltip>
          </Mui.Grid>
          <Mui.Grid item md={6} xs={12}>
            <div className={classes.switchFieldContainer}>
              <Mui.Switch />
              <Mui.Typography variant="body1">Enviar por E-mail</Mui.Typography>
            </div>
          </Mui.Grid>
        </Mui.Grid>
      )}
    </>
  );
}
