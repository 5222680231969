import React from 'react';
import {
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/styles';
import type { IHttpBuyer } from '../../../../types/entities/buyer';
import Dinero from 'dinero.js';

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0 auto',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

interface IReviseChargeProps {
  customers: IHttpBuyer[];
  amount: string;
  installments: string;
  paymentLimitDate: string;
  description: string;
  pix: {
    enabled: boolean;
  };
  boleto: {
    enabled: boolean;
  };
  card: {
    enabled: boolean;
  };
  notification: {
    email: boolean;
    whatsapp: boolean;
  };
}

export function ReviseCharge(props: IReviseChargeProps) {
  const {
    customers,
    amount,
    installments,
    paymentLimitDate,
    description,
    pix,
    boleto,
    card,
    notification,
  } = props;

  const classes = useStyles();

  const [originalAmount, chargeAmount, lastChargeAmount] = React.useMemo(() => {
    const installmentsAsNumber = Number(installments);

    const originalAmount = Dinero({
      amount: Number(amount),
      currency: 'BRL',
    }).setLocale('pt-BR');
    let chargeAmount = Dinero({
      amount: originalAmount.getAmount(),
      currency: originalAmount.getCurrency(),
    }).setLocale('pt-BR');
    let rest = Dinero({
      amount: 0,
      currency: 'BRL',
    }).setLocale('pt-BR');

    if (installmentsAsNumber > 0) {
      chargeAmount = chargeAmount.divide(installmentsAsNumber, 'DOWN');
      rest = originalAmount.subtract(
        chargeAmount.multiply(installmentsAsNumber)
      );
    }

    const lastChargeAmount = chargeAmount.add(rest);

    return [originalAmount, chargeAmount, lastChargeAmount];
  }, [amount, installments]);

  const noNotificationsConfigured =
    !notification.email && !notification.whatsapp;

  return (
    <React.Fragment>
      <CardHeader
        subheader={
          <Typography color="textSecondary" variant="h5">
            Verifique atentamente os dados.
          </Typography>
        }
        title={<Typography variant="h3">Cobrança quase finalizada</Typography>}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item md={3} xs={12}>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Valor total
              </Typography>
              <Typography color="primary" variant="h3">
                {originalAmount.toFormat()}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Parcelado
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {installments} x {chargeAmount.toFormat()}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Valor da última parcela
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {lastChargeAmount.toFormat()}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Vencimento
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {moment
                  .utc(paymentLimitDate)
                  .startOf('day')
                  .format('DD/MM/YYYY')}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Pagamento via
              </Typography>
              {pix.enabled && (
                <Typography color="textSecondary" variant="body1">
                  PIX
                </Typography>
              )}
              {card.enabled && (
                <Typography color="textSecondary" variant="body1">
                  Cartão de crédito
                </Typography>
              )}
              {boleto.enabled && (
                <Typography color="textSecondary" variant="body1">
                  Boleto bancário
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item md={1} xs={12}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item md={8} xs={12}>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Cliente(s)
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {customers.map(customer => (
                  <>
                    {customer.name} - {customer.email}
                    <br />
                  </>
                ))}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Descrição da cobrança
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {description}
              </Typography>
            </Grid>
            <Grid className={classes.marginTop} container direction="column">
              <Typography color="textSecondary" variant="h5">
                Notificações automáticas
              </Typography>
              {noNotificationsConfigured && (
                <Typography color="textSecondary" variant="body1">
                  Nenhuma notificação configurada
                </Typography>
              )}
              {notification.email && (
                <Typography color="textSecondary" variant="body1">
                  Notificar por e-mail ativado
                </Typography>
              )}
              {notification.whatsapp && (
                <Typography color="textSecondary" variant="body1">
                  Notificar por WhatsApp ativado
                </Typography>
              )}
            </Grid>
            {/*{expiration_options && (*/}
            {/*  <Grid className={classes.marginTop} container direction="column">*/}
            {/*    <Typography color="textSecondary" variant="h5">*/}
            {/*      Opções de vencimento*/}
            {/*    </Typography>*/}
            {/*    <Typography color="textPrimary" variant="h6">*/}
            {/*      Receber até {payment_limit_date} dia(s) após a data de*/}
            {/*      vencimento com multa de {late_fee.percentage || '0'}% do valor*/}
            {/*      e juros de {interest.percentage || '0'}% ao mês*/}
            {/*    </Typography>*/}
            {/*  </Grid>*/}
            {/*)}*/}
            {/*{discount_options && (*/}
            {/*  <Grid className={classes.marginTop} container direction="column">*/}
            {/*    <Typography color="textSecondary" variant="h5">*/}
            {/*      Opções de desconto*/}
            {/*    </Typography>*/}
            {/*    <Typography color="textPrimary" variant="h6">*/}
            {/*      Desconto de {getDisplayMoney(discount.amount)} até{' '}*/}
            {/*      {getDisplayDiscountLimitDate()}*/}
            {/*    </Typography>*/}
            {/*  </Grid>*/}
            {/*)}*/}
          </Grid>
        </Grid>
      </CardContent>
    </React.Fragment>
  );
}
