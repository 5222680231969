export declare namespace IPersonDocumentUtils {
  type TDocumentType = 'CPF' | 'CNPJ' | 'Não identificado';
}

export class PersonDocumentUtils {
  public static format(document: string): string {
    const documentType = PersonDocumentUtils.getDocumentType(document);

    switch (documentType) {
      case 'CPF':
        return PersonDocumentUtils.formatCPF(document);
      case 'CNPJ':
        return PersonDocumentUtils.formatCNPJ(document);
      default:
        return document;
    }
  }

  public static getDocumentType(
    document: string
  ): IPersonDocumentUtils.TDocumentType {
    if (document.length === 11) {
      return 'CPF';
    } else if (document.length === 14) {
      return 'CNPJ';
    } else {
      return 'Não identificado';
    }
  }

  public static formatCPF(document: string): string {
    return `${document.substring(0, 3)}.${document.substring(
      3,
      6
    )}.${document.substring(6, 9)}-${document.substring(9, 11)}`;
  }

  public static formatCNPJ(document: string): string {
    return `${document.substring(0, 2)}.${document.substring(
      2,
      5
    )}.${document.substring(5, 8)}/${document.substring(
      8,
      12
    )}-${document.substring(12, 14)}`;
  }
}
