import type {
  EChargeEmailStatus,
  EChargePaymentTypesAccepted,
  EChargeStatus,
  ICharge,
  IHttpPopulateCharge,
} from 'types/entities/charge-entity';
import Dinero from 'dinero.js';
import moment from 'moment';
import { EntityUtils } from './entity-utils';

export class ChargeUtils extends EntityUtils {
  public static statusLabelMap: Record<EChargeStatus, string> = {
    pending: 'Pendente',
    paid: 'Pago',
    failed: 'Falhou',
    canceled: 'Cancelado',
    refunded: 'Reembolsado',
    dispute: 'Em disputa',
    charged_back: 'Estornado',
  };

  public static toStatusLabel(status: IHttpPopulateCharge['status']): string {
    return ChargeUtils.statusLabelMap[status];
  }

  public static chargeEmailStatusLabelMap: Record<EChargeEmailStatus, string> =
    {
      not_send: 'Não enviado',
      send: 'Enviado',
      viewed: 'Visualizado',
    };

  public static toEmailStatusLabel(
    status: IHttpPopulateCharge['emailStatus']
  ): string {
    return ChargeUtils.chargeEmailStatusLabelMap[status];
  }

  public static paymentTypesAcceptedLabelMap: Record<
    EChargePaymentTypesAccepted,
    string
  > = {
    credit: 'Cartão de crédito',
    pix: 'Pix',
    boleto: 'Boleto',
  };

  public static toPaymentTypeLabel(
    paymentTypeAccepted: ICharge.TPaymentTypesAccepted
  ): string {
    return ChargeUtils.paymentTypesAcceptedLabelMap[paymentTypeAccepted];
  }

  public static toPaymentTypesAcceptedLabel(
    paymentTypesAccepted: IHttpPopulateCharge['paymentTypesAccepted']
  ): string[] {
    return paymentTypesAccepted.map(
      paymentTypeAccepted =>
        ChargeUtils.paymentTypesAcceptedLabelMap[paymentTypeAccepted]
    );
  }

  public static shortDescription(description: string): string {
    return description.length > 30
      ? `${description.substring(0, 30)}...`
      : description;
  }

  public static formatPaymentLimitDate<T>(paymentLimitDate: T): T | string {
    if (!paymentLimitDate) {
      return paymentLimitDate;
    }

    return moment.utc(paymentLimitDate).format('DD/MM/YYYY');
  }

  public static formatAmount(amount: number): string {
    const objectAmount = Dinero({ amount: amount, currency: 'BRL' }).setLocale(
      'pt-BR'
    );

    return objectAmount.toFormat();
  }

  public static formatPaidAt(paidAt: string | null): string | null {
    if (!paidAt) {
      return null;
    }

    return moment.utc(paidAt).format('DD/MM/YYYY HH:mm:ss');
  }

  public static formatRefundedAt(refundedAt: string | null): string | null {
    if (!refundedAt) {
      return null;
    }

    return moment.utc(refundedAt).format('DD/MM/YYYY HH:mm:ss');
  }

  public static formatEmailSendAt(emailSendAt: string | null): string | null {
    if (!emailSendAt) {
      return null;
    }

    return moment.utc(emailSendAt).format('DD/MM/YYYY HH:mm:ss');
  }

  public static formatEmailViewedAt(
    emailViewedAt: string | null
  ): string | null {
    if (!emailViewedAt) {
      return null;
    }

    return moment.utc(emailViewedAt).format('DD/MM/YYYY HH:mm:ss');
  }

  public static formatCreatedAt(createdAt: string): string {
    return moment.utc(createdAt).format('DD/MM/YYYY HH:mm:ss');
  }

  public static formatUpdatedAt(updatedAt: string): string {
    return moment.utc(updatedAt).format('DD/MM/YYYY HH:mm:ss');
  }
}
