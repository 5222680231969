import React from 'react';
import useStyles from './table-loading.styles';
import * as Mui from '@material-ui/core';

export function TableLoading() {
  const classes = useStyles();

  return (
    <Mui.Grid
      alignItems="center"
      className={classes.container}
      container
      spacing={2}
    >
      <Mui.CircularProgress />
    </Mui.Grid>
  );
}
