import React from 'react';
import * as Mui from '@material-ui/core';
import { CurrencyField } from '../../../../components/InputCurrency/currency-field.component';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import * as MuiPickers from '@material-ui/pickers';
import type { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Alert } from '@material-ui/lab';
import Dinero from 'dinero.js';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  toolbar: {
    padding: theme.spacing(2),
  },
  toolbarHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarTitle: {
    marginBottom: theme.spacing(0.5),
  },
  container: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  switchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  colorBlack: {
    color: theme.palette.background.default,
  },

  // Pickers
  rootDialog: {
    backgroundColor: theme.palette.background.dark2,
  },
  datePicker: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  buttonPicker: {
    color: theme.palette.white,
    backgroundColor: 'inherit',
  },
}));

interface IChargeBasicConfigProps {
  paymentLimitDate: {
    value: string | null;
    onChange: (momentDate: MaterialUiPickersDate) => void;
  };
  amount: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  description: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  installments: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
}

export function ChargeBasicConfig(props: IChargeBasicConfigProps) {
  const { paymentLimitDate, amount, installments, description } = props;

  const classes = useStyles();

  const [total, rest, lastChargeAmount] = React.useMemo(() => {
    const installmentsAsNumber = Number(installments.value);
    const original = Dinero({
      amount: Number(amount.value),
      currency: 'BRL',
    }).setLocale('pt-BR');
    let total = Dinero({
      amount: original.getAmount(),
      currency: original.getCurrency(),
    }).setLocale('pt-BR');
    let rest = Dinero({
      amount: 0,
      currency: 'BRL',
    }).setLocale('pt-BR');

    if (installmentsAsNumber > 0) {
      total = total.divide(installmentsAsNumber, 'DOWN');
      rest = original.subtract(total.multiply(installmentsAsNumber));
    }

    const lastChargeAmount = total.add(rest);

    return [total, rest, lastChargeAmount];
  }, [amount.value, installments.value]);

  return (
    <div>
      <Mui.AppBar
        className={classes.appBar}
        color="secondary"
        position="static"
      >
        <Mui.Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <Mui.Typography className={classes.toolbarTitle} variant="h5">
              Configurações básicas
            </Mui.Typography>
            <Mui.Typography color="textSecondary" variant="caption">
              Preencha os detalhes da cobrança! Informe a descrição do que está
              sendo vendido ou cobrado, a data em que será feita a cobrança e em
              quantas parcelas (meses) deseja dividir o pagamento, contando a
              partir da data escolhida
            </Mui.Typography>
          </div>
        </Mui.Toolbar>
      </Mui.AppBar>
      <div className={classes.container}>
        <Mui.Grid container spacing={2}>
          <Mui.Grid item md={3} xs={12}>
            <CurrencyField
              fullWidth
              helperText="Ex: R$ 1097,00"
              label="Valor total da cobrança"
              name="amount"
              onChange={amount.onChange}
              type="tel"
              value={amount.value}
              variant="outlined"
            />
          </Mui.Grid>
          <Mui.Grid item md={2} xs={12}>
            <Mui.TextField
              fullWidth
              helperText="Ex: 24 (2 anos)"
              label="Parcelas"
              name="installments"
              onChange={installments.onChange}
              type="tel"
              value={installments.value}
              variant="outlined"
            />
          </Mui.Grid>
          <Mui.Grid item md={3} xs={12}>
            <MuiPickers.MuiPickersUtilsProvider
              libInstance={moment}
              locale="pt-br"
              utils={MomentUtils}
            >
              <MuiPickers.DatePicker
                DialogProps={{
                  PaperProps: {
                    classes: {
                      root: classes.rootDialog,
                    },
                  },
                }}
                cancelLabel="CANCELAR"
                className={classes.datePicker}
                clearLabel="LIMPAR"
                clearable
                format="DD/MM/YYYY"
                fullWidth
                helperText="Ex: 10/12/2017"
                inputVariant="outlined"
                label="Data de vencimento"
                leftArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                name="payment-limit-date"
                onChange={paymentLimitDate.onChange}
                rightArrowButtonProps={{
                  classes: {
                    root: classes.buttonPicker,
                  },
                }}
                value={paymentLimitDate.value}
              />
            </MuiPickers.MuiPickersUtilsProvider>
          </Mui.Grid>
          <Mui.Grid item md={4} xs={12}>
            <Mui.TextField
              fullWidth
              helperText="Ex: Mensalidade do curso de inglês"
              label="Descrição"
              name="description"
              onChange={description.onChange}
              type="text"
              value={description.value}
              variant="outlined"
            />
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Alert severity="info" variant="outlined">
              <Mui.Typography variant="h6">
                Valor a ser cobrado: {installments.value}x parcelas de{' '}
                {total.toFormat()}.
              </Mui.Typography>
              <br />
              <Mui.Typography variant="h6">
                Valor restante não distribuído: {rest.toFormat()}.
              </Mui.Typography>
              <br />
              <Mui.Typography variant="h6">
                Valor da última parcela: {total.toFormat()} + {rest.toFormat()}{' '}
                = {lastChargeAmount.toFormat()}.
              </Mui.Typography>
            </Alert>
          </Mui.Grid>
          <Mui.Grid item xs={12}>
            <Alert severity="warning" variant="outlined">
              <Mui.Typography variant="h6">
                Ao definir uma data de vencimento para a primeira parcela, todas
                as parcelas subsequentes serão geradas a cada 30 dias (D+30) a
                partir dessa data. Isso significa que o pagamento será mensal e
                seguirá esse intervalo fixo.
              </Mui.Typography>
            </Alert>
          </Mui.Grid>
        </Mui.Grid>
      </div>
    </div>
  );
}
