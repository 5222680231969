import palette from '../palette';

export default {
  paper: {
    backgroundColor: palette.background.default,
  },
  icon: {
    color: palette.white,
  },
};
