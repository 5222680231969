import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  Cancel,
  MoreVert,
  PauseCircleFilled,
  Visibility,
} from '@material-ui/icons';
import React from 'react';
import type { IHttpPopulateCharge } from '../../../../../types/entities/charge-entity';
import type {
  IChargeTableListColumn,
  IChargeTableListColumnValue,
} from './charge-list-table.component';
import useStyles from './charge-list-table-row.styles';
import { ChargeViewDialog } from '../../../charge-view-dialog/charge-view-dialog.component';
import { useDialogScreenManager } from '../../../../../components/navigation/use-dialog-screen-magener.hook';
import { useChargeMetrics } from '../../../../../hooks/use-charge/use-charge-metrics.hook';
import { useAuthContext } from '../../../../../hooks/useAuthContext.hook';

interface IChargeListTableRowProps {
  charge: IHttpPopulateCharge;
  columns: IChargeTableListColumn<IChargeTableListColumnValue>[];
}

export function ChargeListTableRow(props: IChargeListTableRowProps) {
  const { charge, columns } = props;

  const { user } = useAuthContext();
  const marketplaceId = user.marketplace_id;

  const classes = useStyles();
  const { stackDialog } = useDialogScreenManager();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { chargeMetrics, isLoadingChargeMetrics } = useChargeMetrics({
    chargeId: charge.id,
    marketplaceId,
  });

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleViewClick() {
    stackDialog(<ChargeViewDialog charge={charge} />, {
      fullScreen: true,
    });
    handleClose();
  }

  return (
    <TableRow hover key={charge.id} role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox">
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu anchorEl={anchorEl} onClose={handleClose} open={isMenuOpen}>
          <MenuItem className={classes.menuItem} onClick={handleViewClick}>
            <Visibility fontSize="small" />
            Visualizar cobranças
          </MenuItem>
          <MenuItem className={classes.menuItem} disabled>
            <Cancel fontSize="small" />
            Cancelar todas as cobranças
          </MenuItem>
          <MenuItem className={classes.menuItem} disabled>
            <PauseCircleFilled fontSize="small" />
            Pausar todas as cobranças
          </MenuItem>
        </Menu>
      </TableCell>
      {columns.map(column => {
        const Component = column.cellComponent;

        return (
          <TableCell align={column.align} key={column.id}>
            <Component
              value={{ charge, chargeMetrics, isLoadingChargeMetrics }}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
}
