export declare namespace IPagination {
  interface IProps {
    page: number;
    limit: number;
    maxLimit: number;
    defaultLimit: number;
  }

  interface IConstructor {
    page?: IPagination.IProps['page'] | null;
    limit?: IPagination.IProps['limit'] | null;
    maxLimit?: IPagination.IProps['maxLimit'] | null;
    defaultLimit?: IPagination.IProps['defaultLimit'] | null;
  }
}

export class Pagination {
  private props: IPagination.IProps;
  private static DEFAULT_PAGE = 1;
  private static DEFAULT_LIMIT = 30;
  private static MAX_LIMIT = 100;
  private static DEFAULT_OFFSET = 0;

  public constructor(props: IPagination.IConstructor) {
    this.props = {
      page: props.page || Pagination.DEFAULT_PAGE,
      limit: props.limit || Pagination.DEFAULT_LIMIT,
      maxLimit: props.maxLimit || Pagination.MAX_LIMIT,
      defaultLimit: props.defaultLimit || Pagination.DEFAULT_LIMIT,
    };
  }

  public get defaultLimit(): number {
    return this.props.defaultLimit;
  }

  public get maxLimit(): number {
    return this.props.maxLimit;
  }

  public get limit(): number {
    if (this.props.limit > this.maxLimit) {
      return this.maxLimit;
    }

    return this.props.limit;
  }

  public get page(): number {
    return this.props.page;
  }

  public get offset(): number {
    if (this.page > 1) {
      return (this.page - 1) * this.limit;
    }

    return Pagination.DEFAULT_OFFSET;
  }
}
