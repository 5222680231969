import React from 'react';
import * as Mui from '@material-ui/core';

const useStyles = Mui.makeStyles(theme => ({
  appBar: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  toolbar: {
    padding: theme.spacing(2),
  },
  toolbarHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarTitle: {
    marginBottom: theme.spacing(0.5),
  },
  container: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  switchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
}));

interface IChargeSendConfigProps {
  email: {
    onEnableChange: (enabled: boolean) => void;
    enabled: boolean;
  };
  whatsapp: {
    onEnableChange: (enabled: boolean) => void;
    enabled: boolean;
  };
}

export function ChargeSendConfig(props: IChargeSendConfigProps) {
  const { email, whatsapp } = props;

  const classes = useStyles();

  function handleEmailEnableChange(
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    email.onEnableChange(checked);
  }

  function handleWhatsappEnableChange(
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    whatsapp.onEnableChange(checked);
  }

  return (
    <div>
      <Mui.AppBar
        className={classes.appBar}
        color="secondary"
        position="static"
      >
        <Mui.Toolbar className={classes.toolbar}>
          <div className={classes.toolbarHeader}>
            <Mui.Typography className={classes.toolbarTitle} variant="h5">
              Configurações de envio
            </Mui.Typography>
            <Mui.Typography color="textSecondary" variant="caption">
              Escolha se você quer que enviemos a cobrança para o seu cliente
              pelo E-mail ou WhatsApp ou se preferir você pode conectar a nossa
              API e enviar de forma personalizada
            </Mui.Typography>
          </div>
        </Mui.Toolbar>
      </Mui.AppBar>
      <Mui.Grid className={classes.container} container>
        <Mui.Grid item md={6} xs={12}>
          <Mui.Tooltip arrow title="Em breve">
            <div className={classes.switchFieldContainer}>
              <Mui.Switch
                checked={whatsapp.enabled}
                disabled
                onChange={handleWhatsappEnableChange}
              />
              <Mui.Typography variant="body1">
                Enviar por WhatsApp
              </Mui.Typography>
            </div>
          </Mui.Tooltip>
        </Mui.Grid>
        <Mui.Grid item md={6} xs={12}>
          <div className={classes.switchFieldContainer}>
            <Mui.Switch
              checked={email.enabled}
              onChange={handleEmailEnableChange}
            />
            <Mui.Typography variant="body1">Enviar por E-mail</Mui.Typography>
          </div>
        </Mui.Grid>
      </Mui.Grid>
    </div>
  );
}
