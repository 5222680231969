import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  cardContent: {
    padding: '0 auto',
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
  paperDisabled: {
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.7,
  },
  paperSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  checkbox: {
    padding: 'auto 0',
  },
  checked: {
    color: theme.palette.white,
  },
  cardHeaderAction: {
    alignSelf: 'flex-end',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  menuList: {
    backgroundColor: theme.palette.background.dark2,
  },
  disabled: {
    color: `${theme.palette.complementary.lightGrey2} !important`,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemSelected: {
    color: theme.palette.text.primary,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  subTitle: {
    marginTop: theme.spacing(1),
  },
  rootDialog: {
    backgroundColor: theme.palette.background.dark2,
  },
  buttonPicker: {
    color: theme.palette.white,
    backgroundColor: 'inherit',
  },
  popover: {
    pointerEvents: 'none',
  },
  paperPopover: {
    backgroundColor: theme.palette.background.dark2,
    padding: theme.spacing(1),
    maxWidth: 310,
  },
  usersWithoutAddressTitle: {
    padding: theme.spacing(2),
  },
  configContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
