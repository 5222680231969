import React from 'react';
import type { ISnackbarEnqueueOptionsProps } from './snackbar.component';

interface ISnackbarContextProps {
  enqueueSnackbar: (
    message: string,
    options?: ISnackbarEnqueueOptionsProps
  ) => void;
}

export const SnackbarContext = React.createContext<ISnackbarContextProps>(
  {} as ISnackbarContextProps
);
