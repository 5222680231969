import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import type { IHttpPopulateCharge } from '../../../../../types/entities/charge-entity';

import useStyles from './charge-list-table.styles';
import { useSearchParams } from '../../../../../hooks/use-search-params.hook';
import type { IHttpPopulateChargeMetrics } from '../../../../../types/entities/charge-metrics-entity';
import { ChargeListTableCell } from './charge-list-table-cell.component';
import { ChargeListTableRow } from './charge-list-table-row.component';

export interface IChargeTableListColumnValue {
  charge: IHttpPopulateCharge;
  chargeMetrics: IHttpPopulateChargeMetrics | null;
  isLoadingChargeMetrics: boolean;
}

export interface IChargeTableListColumn<T> {
  id: string;
  label: string;
  align: 'right' | 'left' | 'center';
  cellComponent: React.FC<{ value: T }>;
}

interface IChargeTableListProps {
  pagination: {
    limit: number;
    page: number;
    total: number;
  };
  charges: IHttpPopulateCharge[];
}

export function ChargeTableList(props: IChargeTableListProps) {
  const { pagination, charges } = props;

  const classes = useStyles();

  const searchParams = useSearchParams();

  function handlePageChange(
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) {
    searchParams.add('page', String(page + 1));
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    searchParams.add('limit', event.target.value);
    searchParams.add('page', '1');
  }

  const columns: IChargeTableListColumn<IChargeTableListColumnValue>[] =
    React.useMemo(
      () => [
        {
          id: 'chargeId',
          label: 'ID da cobrança',
          align: 'center',
          cellComponent: ChargeListTableCell.Id,
        },
        {
          id: 'buyer',
          label: 'Cliente',
          align: 'left',
          cellComponent: ChargeListTableCell.Buyer,
        },
        {
          id: 'status',
          label: 'Status',
          align: 'left',
          cellComponent: ChargeListTableCell.Status,
        },
        {
          id: 'description',
          label: 'Descrição',
          align: 'left',
          cellComponent: ChargeListTableCell.Description,
        },
        {
          id: 'amount',
          label: 'Valor total',
          align: 'right',
          cellComponent: ChargeListTableCell.TotalAmount,
        },
        {
          id: 'totalAmountPaid',
          label: 'Valor total pago',
          align: 'right',
          cellComponent: ChargeListTableCell.TotalAmountPaid,
        },
        {
          id: 'installments',
          label: 'Nº cobranças',
          align: 'center',
          cellComponent: ChargeListTableCell.Installments,
        },
      ],
      []
    );

  return (
    <Paper className={classes.paper}>
      <TablePagination
        SelectProps={{
          classes: {
            icon: classes.icon,
          },
          MenuProps: {
            classes: {
              paper: classes.menuList,
            },
          },
        }}
        backIconButtonText="Voltar página"
        className={classes.paginationContainer}
        component="div"
        count={pagination?.total || 0}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `mais que ${to}`
          }`
        }
        labelRowsPerPage=""
        nextIconButtonText="Próxima página"
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={(pagination?.page || 1) - 1}
        rowsPerPage={pagination?.limit || 30}
        rowsPerPageOptions={[30, 50, 100]}
      />

      <TableContainer>
        <Table aria-label="Lista de cobranças" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTh} padding="checkbox" />
              {columns.map(column => (
                <TableCell
                  align={column.align}
                  className={classes.tableTh}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {charges.map(charge => (
              <ChargeListTableRow
                charge={charge}
                columns={columns}
                key={charge.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        SelectProps={{
          classes: {
            icon: classes.icon,
          },
          MenuProps: {
            classes: {
              paper: classes.menuList,
            },
          },
        }}
        backIconButtonText="Voltar página"
        component="div"
        count={pagination?.total || 0}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} de ${
            count !== -1 ? count : `mais que ${to}`
          }`
        }
        labelRowsPerPage=""
        nextIconButtonText="Próxima página"
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        page={(pagination?.page || 1) - 1}
        rowsPerPage={pagination?.limit || 30}
        rowsPerPageOptions={[30, 50, 100]}
      />
    </Paper>
  );
}
